.container-404 {
  display: flex;
  flex-direction: column;
  //flex: 1;
  justify-content: center;
  align-items: center;
  //height: calc(100vh - 140px);
  background: #E7ECEF;
  width: calc(100vw - 211px);
  height: calc(100vh - 78px);
  left: 211px;
  top: 0;
  position: absolute;

  .notFountTitle {
    margin-top: 30px;
    font-size: 1.375rem;
  }

  .notFoundDescription {
    font-weight: 300;
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: center;
  }

  img{
    width: 93px;
    height: 93px;
  }

  .home{
    background: #0B2541;
    color:#fff;
    padding: 10px 25px;
    border-radius: 20px;
    font-size: 1.0625rem;
  }
}
