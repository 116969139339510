@import "style";

.centerMin {
  text-align: center;
  font-size: 14px;
}

.filterCheck {
  display: flex;
  align-items: center;
  padding: 0px;
  background: transparent;
  &:focus {
    outline: black;
    outline-style: auto;
    outline-offset: 2px;
  }
  img {
    margin-right: 5px;
    display: block;
    width: 20px;
    height: 20px;
  }
  p {
    font-size: 14px;
    cursor: pointer;
    font-weight: 700 !important;
  }
}

.PublishingActions {
  background-color: #ffffff;

  .infoContainer {
    &__top {      
      padding: 22px 20px 35px 20px;
      border-bottom: 1px solid #d3d9de;
      &__skole {
        display: flex;
        align-items: center;
        border-top: solid 1px #9d9d9d26;
        margin-top: 20px;
        padding-top: 15px;
        p {
          margin-right: 20px;
        }
        .skoleInput {
          min-width: 210px;
        }
      }
    }
    &__secondTitle {
      h2 {
        margin: 0px;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 16px;
        color: #AD005F;
      }
      p {
        font-size: 16px;
        max-width: 600px;
        margin: 0px;
        margin-top: 15px;
        margin-bottom: 20px;
        font-weight: 300;
      }
    }    
    &__bottom {      
      padding: 25px 20px 45px 20px;
      border-bottom: 1px solid #d3d9de;
      .InformationSource {
        margin-bottom: 20px;
        h2 {
          margin-bottom: 20px !important;
        }
        .itemsFlex {
          display: flex;
          align-items: center;
        }
        .TagInput {
          max-width: 350px;
          margin-right: 20px;
          margin-bottom: 10px;
        }
        .bloqued {
          opacity: 0.4;
          pointer-events: none;
        }
      }
    }
    &__filters {      
      display: flex;
      flex-wrap: wrap;
      .itemsFlex {
        margin-bottom: 10px;
        margin-right: 10px;
        max-width: 48%;
        min-width: 350px;
        &.loadingItem {
          opacity: 1;
        }
        .SearchFilter__select {
          margin-right: 0px;
          margin-bottom: 0px;
          width: 100%;
          height: auto;
          padding: 12px 10px;
          padding-right: 30px;
        }
      }
    }
    &__body {
      margin: 20px 0px;
      &__title {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        img {
          width: 22px;
          height: 22px;
          margin-right: 5px;
        }
        h3 {
          margin: 0px;
          font-size: 16px;
        }
      }
      &__table {       
        .itemTablesTd {
          flex: 1;
          font-size: 14px;
          &.icons {
            width: 20px;
            flex: none;
            margin-right: 10px;
            button {
              padding: 0px;
              background: transparent;
              display: block;
              &:focus {
                outline: black;
                outline-style: auto;
                outline-offset: 2px;
              }
              img {
                display: block;
                width: 20px;
                &.checkImgFalse {
                  display: none;
                }
              }
              &.active {
                img {
                  &.checkImg {
                    display: none;
                  }
                  &.checkImgFalse {
                    display: block;
                  }
                }
              }
            }
            a {
              display: block;
              img {
                display: block;
                width: 20px;
              }
            }
          }
          &.grade {
            max-width: 115px;
            margin-right: 15px;
            span {
              &:after {
                content: ', ';
              }
              &:last-child {
                &:after {
                  display: none;
                }
              }
            }
          }
          &.core {
            margin-right: 15px;
            span {
              &:after {
                content: ', ';
              }
              &:last-child {
                &:after {
                  display: none;
                }
              }
            }
          }
          &.subjects {
            max-width: 150px;
          }       
        }
        .itemTablesHeader {          
          .itemTablesTh {
            display: flex;
            margin-bottom: 20px;
          }
        }
        .itemTablesBody {
          padding-bottom: 80px;
          .itemTablesTr {
            display: flex;
            border-bottom: solid 1px #d3d9de;
            padding-bottom: 10px;
            padding-top: 10px;
          }
        } 
      }
    }
    &__hidden {
      display: none;
    }

    .title {
      font-size: 1.0625rem;
      padding-left: 10px;
    }

    .filterBy {
      flex-direction: row;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 10px 30px 7px 5px;
      border: 1px solid #596A75;
      border-radius: 5px;
      background: #E7ECEF url(../../../../assets/images/search-bold.svg) no-repeat right 8px top 14px;;
    }

    .darkTheme .tagWrapper .tag {
      background: #0B2541;
      border-color: #0A243F;

      button {
        background: transparent;
        padding: 0px;
        &:focus {
          outline: auto;
        }
      }
      span {
        color: white;
      }
      img {
        color: white;
      }
    }

    .studentLevelButtons {
      width: 100%;
      height: 52px;
      margin-top: 8px;

      button {
        display: flex;
        background-color: #FFF;
        justify-content: center;
        align-items: center;
        width: calc(100% / 3);
        border: 1px solid #596A75;
        font-weight: 300;
        &:focus {
          outline: auto;
          outline-offset: -4px;
        }

        &:first-child {
          border-radius: 5px 0 0 5px;
        }

        &:last-child {
          border-radius: 0 5px 5px 0;
        }

        &.active {
          background-color: #0B2541;
          border: 1px solid #0B2541;
          color: #ffffff;

          img {
            filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(300deg) brightness(107%) contrast(101%);
          }
        }

        img {
          margin-right: 10px;
        }
      }
    }
    .visibility {
      .flex-align {
        align-items: center;
      }
      p {
        font-size: 16px;
        max-width: 550px;
        margin: 0px;
        margin-top: 15px;
        margin-bottom: 20px;
        font-weight: 300;
      }
    }

    .visibilityButtons {
      width: 100%;
      height: 52px;
      margin-top: 7px;
      max-width: 540px;
      &.preloading {
        opacity: 0.3;
        pointer-events: none;
      }
      button {
        background-color: #E7ECEF;
        border: 1px solid #596A75;
        font-weight: 300;
        min-width: 180px;
        &:focus {
          outline: auto;
          outline-offset: -4px;
        }

        &:first-child {
          border-radius: 5px 0 0 5px;
        }

        &:last-child {
          border-radius: 0 5px 5px 0;
        }

        &.active {
          background-color: #0B2541;
          border: 1px solid #0B2541;
          color: #ffffff;

          img {
            filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(300deg) brightness(107%) contrast(101%);
          }
        }

        img {
          margin-right: 10px;
        }
      }
    }
  }
}
.css-1wa3eu0-placeholder {
  color: #0b2541 !important;
}
@media screen and (max-width: $screen-dimension-desktop) {
  .PublishingActions {
    height: calc(100% - #{$header-height});
    .infoContainer {
      .studentLevelButtons button img {
        width: 15px;
      }
    }
  }
}
