.leftside {
  flex: 3;
  margin-bottom: 20px;

  .titleEvaluation {
    margin: 40px 0 10px;
    color: #AD005F;
    text-transform: uppercase;
    font-size: 1rem;
  }

  .titleAssignment {
    font-size: 2.25rem;
    margin-bottom: 20px;
  }

  .contentQuestions {
    background: white;
    padding: 19px 27px 19px 19px;
    border-radius: 4px;
    border: 1px solid #D3D9DE;
  }

}

.rightside {
  flex: 2;
  background: white;
  margin-left: 20px;
  box-shadow: 0 2px 5px 0 #d3d9de;
  border-radius: 5px;
  height: fit-content;

  .comments {
    display: flex;
    flex-direction: column;

    .comment {
      display: flex;
      border-bottom: 1px solid #D3D9DE;
      width: 100%;
      padding: 20px 20px 25px 20px;
      flex-direction: column;

    }

    .evaluation {
      display: flex;
      padding: 22px 20px 0;
      flex-direction: column;
    }

    .headline {
      color: #0B2541;
      font-weight: bold;
      font-size: 1.125rem;
    }

    .withPadding {
      padding-bottom: 21px;
    }
  }
}
