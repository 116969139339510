@import "style";

.header {
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  z-index: 20;
  background-color: #ffffff;
  height: 80px;
  border-top: 10px solid #AD005F;
  padding: 0 20px;
  border-bottom: 1px solid #e4e9ed;

  .assignmentTabs {
    height: 100%;
    margin-left: 99px;
    position: relative;
  }

  .back-button {
    cursor: pointer;
    white-space: nowrap;
    img {
      margin-right: 10px;
    }
  }

  .link {
    text-transform: uppercase;
    height: 100%;
    position: relative;
    margin: 0 20px;
    color: #939fa7;
    font-weight: 500;
    font-size: 0.9375rem;
  }

  .disabled-link {
    pointer-events: none;
  }

  .activeRoute {
    color: #0B2541;

    &::after {
      position: absolute;
      content: "";
      border-bottom: 4px solid #AD005F;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }

  .doneBox {
    span {
      color: #596A75;
      margin-right: 20px;
      font-size: 0.9375rem;
    }
  }

  .CreateButton {
    margin: 0 5px;
  }
}

@media screen and (min-width: 1400px) {
  .header .assignmentTabs {
    right: -123px;
  }
}

//.dark {
//  position: absolute;
//  top: 80px;
//  left: 0;
//  width: 100%;
//  height: calc(100% - 80px);
//  z-index: 9;
//  background: rgba(54, 57, 71, 0.3);
//}

@media screen and (max-width: $screen-dimension-desktop), screen and (max-width: 1080px){
  .header {

    .link {
      font-size: 0.75rem !important;
    }

    .doneBox {
      span {
        font-size: 0.75rem !important;
      }

      .CreateButton {
        font-size: 0.9375rem;
      }
    }
  }
}
