.SearchPage {
    &__header {
        border-radius: 8px;
        background-color: #FFF;
        border: 1px solid #D3D9DE;
        margin-bottom: 20px;
        padding-left: 20px;
        padding-top: 20px;
        padding-right: 20px;
        &__top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            &__left {
                width: calc(100% - 750px);
                position: relative;
                img {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    &.closeIcon {
                        top: 5px;
                        left: auto;
                        right: 10px;
                        cursor: pointer;
                    }
                }
                input {
                    width: 100%;
                    border-radius: 28px;
                    border: solid 1px #cccccc;
                    padding-left: 35px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
            }
            &__right {
                display: flex;
                align-items: center;
                width: 710px;
                justify-content: space-between;
                position: relative;
                .allSpark {
                    margin-right: 10px;
                }
                .CreateButton {
                    background: #e7ecef;
                    padding: 5px 10px;
                    border: 1px solid #939fa7;
                    color: #0B2541;
                    display: flex;
                    align-items: center;
                    margin-left: 10px;
                    justify-content: center;
                    font-weight: 300;
                    img {
                        max-width: 20px;
                        margin-right: 10px;
                    }
                    &:hover {
                        background: white;
                        color:  #0b2541;
                    }
                    &.active {
                        background: #0b2541;
                        color: white;
                        img {
                            filter: brightness(0)invert(1);
                        }
                    }
                }
                .listLenguagesComplete {
                    display: flex;
                    align-items: center;
                    .listLenguajesList {
                        position: relative;
                    }
                    .listLenguajes {
                        display: flex;
                        position: relative;
                        min-width: 185px;
                        .css-1y8mk30-SkeletonTheme {
                            height: 40px;
                            position: relative;
                            top: -5px;
                            margin: 0px;
                        }
                        .css-1y8mk30-SkeletonTheme span {
                            height: 40px !important;
                        }
                        .CreateButton {
                            min-width: 80px;
                        }
                    }
                }
            }
        }
        &__bottom {
            position: relative;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            .renderTabs {
                display: flex;
                .renderTab {
                    font-size: 15px;
                    margin-right: 20px;
                    padding-bottom: 8px;
                    border-bottom: solid 3px #ffffff;
                    display: flex;
                    align-items: center;
                    white-space: nowrap;
                    img {
                        width: 18px;
                        margin-right: 5px;
                    }
                    &.active {
                        border-bottom: solid 3px #AD005F;
                        color: #AD005F;
                    }
                }
            }
            .listLenguajes {
                padding-bottom: 8px;
                button {
                    background: transparent;
                    padding: 0px;
                    position: relative;
                    padding-left: 22px;
                    margin-left: 18px;
                    font-size: 14px;
                    cursor: pointer;
                    &:before {
                        content: '';
                        width: 16px;
                        height: 16px;
                        position: absolute;
                        border: solid 1px #0b2541;
                        left: 0px;
                        border-radius: 3px;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        width: 4px;
                        height: 10px;
                        border-bottom: solid 2px white;
                        border-right: solid 2px white;
                        transform: rotate(45deg);
                        left: 6px;
                        top: 1px;
                        opacity: 0;
                    }
                    &.active {
                        &:before {
                            background: #0b2541;
                        }
                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }
            button#ButtonCloseTpInside img {
                margin-right: 6px;
                width: 20px;
            }
            button#ButtonCloseTpInside {
                display: flex;
                background: #e7ecef;
                padding: 5px 10px;
                border: 1px solid #939fa7;
                color: #0b2541;
                align-items: center;
                position: relative;
                top: -5px;
                font-weight: 200;
                border-radius: 20px;
                height: 40px;
                font-size: 1.0625rem;
            }
        }
    }
}
.emptyTeachingPaths {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 300;
    height: 100%;
}
.contentListSearch.articlesList {
    margin-right: -18px;
}
.absModalTinker {
    position: absolute;
    background-color: #FFF;
    border: 1px solid #D3D9DE;
    top: 45px;
    border-radius: 7px;
    overflow: hidden;
    right: 0;
    z-index: 2;
    button {
        display: block;
        width: 100%;
        background: white;
        border-bottom: solid 1px #d7d7d769;
        padding: 5px 10px;
        min-width: 150px;
        box-sizing: border-box;
        &:hover, &.active {
            background: black;
            color: white;
        }        
        &:focus {
            background: #dbdbdb !important;
            color: #0b2541 !important;
        }
    }
}
@media (max-width: 1200px) {
    .SearchPage__header__top {
        display: block;
    }
    .SearchPage__header__top__left {
        width: 100%;
        margin-bottom: 15px;
    }
    .SearchPage__header__top__right {
        width: 100%;
        max-width: 710px;
    }
}
@media (max-width: 876px) {
    .SearchPage__header__bottom .renderTabs {
        width: 100%;
        overflow-x: scroll;
    }
    button#ButtonCloseTpInside {
        margin: 0px;
        margin-bottom: 10px;
    }
    .SearchPage__header__top {
        margin-bottom: 10px !important;
    }
    .contentListSearch .Inside.Inside--horizontal {
        min-height: auto;
        height: auto;
    }
    .contentListSearch .Inside .cardInfoItem .cardInfoItem--horizontal {
        display: block;
        min-height: auto;
        height: auto;
    }
    .contentListSearch .Inside .cardInfoItem__right .subjectItems .subjectItem {
        margin-bottom: 5px;
    }
    .contentListSearch .Inside .cardInfoItem__right {
        padding: 5px;
    }
    .contentListSearch .Inside .cardInfoItem__right .subjectItems {
        flex-wrap: wrap;
    }
    .SearchPage__header__top__right {
        flex-wrap: wrap;
    }
    .SearchPage__header__top__right .listLenguagesComplete {
        flex-wrap: wrap;
        width: 100%;
        flex: none;
        justify-content: space-between;
    }
    .SearchPage__header__top__right .allSpark {
        font-size: 14px;
    }
    .SearchPage__header__top__right .listLenguagesComplete .listLenguajes .CreateButton {
        margin-top: 0px;
    }
    .SearchPage__header__top__right .listLenguagesComplete .listLenguajesList {
        flex: none;
        width: 100%;
        margin: 10px 0px;
        margin-bottom: 0px;
    }
    .SearchPage__header__top__right .CreateButton {
        width: 100% !important;
        box-sizing: border-box;
        min-width: 100%;
    }
    .SearchPage__header__bottom {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        align-items: flex-start;
    }
    .SearchPage__header__bottom__right {
        width: 100%;
        margin-bottom: 15px;
    }
    .SearchPage__header__bottom button#ButtonCloseTpInside {
        top: 0px;
        width: 100%;
        justify-content: center;
    }
    .SearchPage__header__bottom .renderTabs .renderTab {
        font-size: 13px;
    }
}
