@import "../../../style";

.WidgetHeader {
  position: relative;
  padding: 16px 18px;
  min-height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .OptionMsj {
    position: relative;
    /*
    &:before {
      position: absolute;
      box-shadow: 0 2px 2px #00000021;
      margin-top: 20px;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      border-radius: 4px;
      border: 1px solid #596a75;
      padding: 20px;
      cursor: default;
      background: #596a75;
      content: attr(data-msj);
      right: -2px;
      top: 18px;
      opacity: 0;
      pointer-events: none;
      z-index: 1;
      color: white;
      font-size: 1.0625rem;
    }
    &:after {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      background: #596a75;
      right: 1px;
      -webkit-transform: translate(-50%,-50%) rotate(-45deg);
      transform: translate(-50%,-50%) rotate(-45deg);
      background-color: #596a75;
      border-right: 1px solid #596a75;
      border-top: 1px solid #596a75;
      top: 38px;
      z-index: 1;
      opacity: 0;
      pointer-events: none;
    }
    &:hover {
      &:before, &:after {
        opacity: 1;
      }
    }
    */
  }
}

.WidgetHeader__title {
  @include text-shrink;
  font-size: 1.0625rem;
}

.WidgetHeader__image {
  width: 26px;
  height: 26px;
  cursor: pointer;
}

.WidgetHeader__tooltip {
  z-index: 2;
  right: 5px;
  position: absolute;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.129753);
  margin-top: 20px;
  top: 34px;
  width: max-content;
  border-radius: 4px;
  border: 1px solid #596A75;
  padding: 20px;
  cursor: default;
  background: #fff;

  ul li {
    cursor: pointer;
  }

  i {
    position: absolute;
    top: -6px;
    right: 20px;
    margin-top: -12px;
    width: 12px;
    height: 24px;
    transform: rotate(-90deg);

    &::after {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      left: 0;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      background-color: #fff;
      border-right: 1px solid #596A75;
      border-bottom: 1px solid #596A75;
    }
  }
}
