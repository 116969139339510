@import "../../../style/index";

.Popup {
  position: fixed;
  top: $header-height;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.Popup_animated-enter {
  transform: scale(0.9);
  opacity: 0;
}

.Popup_animated-enter-active {
  transform: scale(1);
  opacity: 1;
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.Popup_animated-exit {
  opacity: 1;
}

.Popup_animated-exit-active {
  opacity: 0;
  transform: translateY(400px);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.Popup__overlay {
  border-top: 1px solid $color-gray;
  padding-top: 75px;
  position: relative;
  width: 100%;
  height: 100%;
  background: rgba($color-white, 0.95);
}

.Popup__content {
  width: 100%;
  height: 100%;
}

.Popup__button {
  position: absolute;
  right: 20px;
  top: 20px;
  background: $color-purple;
  color: $color-white;
  border-radius: 20px;
  padding: 9px 47px 9px 18px;
  z-index: 1;
}

.Popup__buttonImage {
  @include absolute-center-y;
  right: 11px;
  width: 24.38px;
  height: 24.38px;
}

@media screen and (max-width: $screen-dimension-mobile) {
  .Popup__button {
    top: 0;
    right: 0;
  }

  .Popup_animated-exit-active {
    transform: translateY(100%);
  }

  .Popup__overlay {
    padding-top: 38px;
  }
}
