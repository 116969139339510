@import "../../../style";

.SearchFilter {
  display: flex;
  justify-content: space-between;
  background: $color-white;
  border-radius: 8px;
  padding: 14px 0 0 20px;
  box-shadow: 0 2px 2px 0 $color-dark-gray;
  border: 1px solid $color-dark-gray;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: padding-box;
  min-height: fit-content;
  
  .centerMin {
    text-align: left;
    font-size: 16px;
  }
  select:focus > option:checked { // TODO use BEM here
    font-weight: bold !important;
  }
}

.SearchFilter__link {
  margin-bottom: 14px;
  button, a {
    display: flex;
    background: #e7ecef;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #939fa7;
    color: #0B2541;
    font-weight: 200;
    margin-right: 20px;
    font-size: 16px;
    &.openhandler {
      background: #0B2541;
      border: 1px solid #0B2541;
      color: white;
    }
    img {
      margin-right: 5px;
    }
    &:focus {
      outline: black;
      outline-offset: 4px;
      outline-style: auto;
    }
  }
}

.SearchFilter__select {
  margin-right: 20px;
  margin-bottom: 14px;
  position: relative;
  padding-right: 28px;
  height: 33px;
  border-radius: 5px;
  border: 1px solid $color-regent-gray;
  color: $color-text-primary;
  font-weight: 300;
  font-size: 0.9375rem;
  min-width: 150px;
  background: url(../../../assets/images/arrow-down.svg) no-repeat right 10px center, $color-light-gray;
  transition: 0.3s;
  max-width: 320px;
  cursor: pointer;
  &:hover {
    border-color: #0b2541;
  }
}

.SearchFilter__option {
  background: $color-light-gray;
  color: $color-text-primary;
}

.SearchFilter__search__submit {
  background: url(/static/media/search.9aa844cf.svg) no-repeat;
  background-position: 7px 6px;
  cursor: pointer;
  width: 34px;
  height: 34px;
  background-color: #e7ecef;
  border-radius: 50%;
  border: solid 1px #0b25415c;
  transition: 0.3s;
  &:hover {
    border: solid 1px #0b2541;
  }
}

.SearchFilter__search__content {
  display: flex;
  margin-left: 20px;
  margin-bottom: 15px;
  margin-right: 20px;
}

.SearchFilter__search {

  display: block;
  padding: 5px 10px 6px;
  border: none;
  border-left: 1px solid;
  border-radius: unset;
  width: 175px;
  font-size: .9375rem;
  font-weight: 300;

  &::placeholder {
    @include text-shrink;
    color: $color-text-primary;
    font-size: 0.9375rem;
    font-weight: 300;
  }
  .large-search {
    width: 227px;
  }
  .small-search {
    width: 137px;
  }
}

.SearchFilter__search__submit {
  background: url(../../../assets/images/search.svg) no-repeat right center;
  background-position: 7px 6px;
  border: none;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: #e7ecef;
  pointer-events: none;
}

.SearchFilter__space {
  display: flex;
  justify-content: space-between;
  flex-grow: 2;
}

.SearchFilter__select_sorting {
  width: 232px;
}

.FiltersModal {
  position: fixed;
  left: 0;
  width: 500px;
  padding: 20px 30px;
  padding-right: 18px;
  box-sizing: border-box;
  background: white;
  height: calc(100% - 80px);
  top: 80px;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 99;
  &__header {
    display: flex;
    justify-content: space-between;
    h5 {
      margin: 0px;
      font-size: 1.2rem;
      font-weight: 400;
      color: #AD005F;
    }
    button {
      display: flex;
      background: transparent;
      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
      &:focus {
        outline: black;
        outline-style: auto;
        outline-offset: 3px !important;
      }
    }
  }
  &__body {
    padding: 10px 0px;
    &__item {
      margin: 30px 0px;
      .itemFilter {
        display: flex;
        &__left {
          margin-right: 10px;
          img {
            width: auto;
            height: 25px;
          }
        }
        &__leftWithoutIcon {
          margin-right: 10px;
          margin-left: 20px;
        }
        &__right {
          h3 {
            margin-top: 2px;
            font-size: 16px;
            margin-bottom: 15px;
            font-weight: 500;
          }
          .flexFilter {
            display: flex;
            flex-wrap: wrap;
          }
          .itemFlexFilter {
            font-size: 14px;
            background: #e7ecef;
            padding: 3px 8px;
            margin-right: 5px;
            margin-bottom: 5px;
            border-radius: 5px;
            font-weight: 200;
            color: black;
            &.active {
              background: #0b2541;
              color: white;
            }
            &:focus {
              outline: black;
              outline-style: auto;
              outline-offset: 2px;
            }
            &.downlight {
              color: #808080b8;
            }
          }
        }
        &__core {
          min-width: 300px;
          max-width: 420px;
        }
      }
    }
  }
}
.FiltersModal__backgroundside {
  position: fixed;
  top: 0px;
  left: 500px;
  width: calc(100% - 500px);
  height: 100%;
  background: transparent;
  z-index: -1;
  cursor: pointer;
}
.fixedsModal .FiltersModal {
  top: 78px;
  height: calc(100% - 78px);
}
.filtersModalBackground {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.4;
}



// media rule for tablets contains in component file

@media screen and (max-width: $screen-dimension-tablet) {
  .SearchFilter__select {
    flex: 1 1 100% !important; // !important needed here because media rule that contained in component class has higher specificity
  }
}
