.extra {
  .extra-button {
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    background-color: #D3D9DE;
    border-radius: 5px;
    padding: 4px 6px;
    max-height: 28px;
    min-height: 28px;
    height: auto;
    text-align: center;

    img {
      display: flex;
      align-self: center;
    }
  }

  button:hover {
    background-color: #ccc;
    border-radius: 5px;
  }
}
