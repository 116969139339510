@import "style";

.EvaluationPage {
  height: 100%;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 575px) {
  .EvaluationPage {
    height: auto;
  }
  .AssignmentDistributeList {
    display: flex;
  }
  .AssignmentDistributeItem {
    margin-right: 10px;
    margin-bottom: 5px;
  }
}