@import "style";

.passageTeachingPath {
  display: flex;
  position: absolute;
  background: white;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  z-index: 20;
  .passageTeachingPathBreadCrumbs {
    position: absolute;
    top: 0;
    background: rgb(231, 236, 239);
    width: 100%;
    height: 11px;
    z-index: 99;
    ul {
      display: flex;
      align-items: stretch;
      height: 100%;
      li {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;        
        position: relative;
        &:before {
          content: '';
          height: 6px;
          width: 6px;
          background: #AD005F;
          border-radius: 50%;
          display: block;
        }
        &:after {
          content: '';
          position: absolute;
          width: 0;
          height: 100%;
          background: #AD005F;
          left: 0;
        }
        &.used {
          &:after { width: 100%; }
          &.rounded {
            &:after {
              border-top-right-radius: 7px;
              border-bottom-right-radius: 7px;
            }                        
          }
        }
        &:last-child {
          &:before { display: none; }
          &:after { border-radius: 0px !important; }
        }
      }
    }
  }
  .teachingPathNewBreadCrumbs {
    position: absolute;
    top: 25px;
    z-index: 99;
    align-items: center;
    display: flex;
    background: white;
    left: 50%;
    transform: translateX(-50%);
    .breadcrumb {
      padding: 10px;
      height: auto;
      margin: 0px;
      border-top: none;
      .breadcrumbs {
        align-items: center;
        li {
          font-size: 15px;
        }
      }
    }
    button {
      background: transparent;
      &.navigationNextButton img {
        transform: rotate(180deg);
      }
      &:disabled {
        opacity: 0.5;
        pointer-events: none;
      }
      img {
        width: 20px;
      }
    }
  }

  .teachingPathNavigate {
    display: flex;
    flex-direction: column;
    background: #E7ECEF;
    width: 230px;
    min-width: 230px;
    justify-content: space-between;

    .stepOverview {
      display: flex;
      padding: 20px;
      flex-direction: column;
      span {
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .overview {
        font-size: 0.9375rem;
        color: #AD005F;
        text-transform: uppercase;
        margin-bottom: 10px;

        span {
          word-break: break-word;
        }
      }

      .titleTeachingPath {
        margin: 0px;
        font-size: 1.2rem;
        text-overflow: ellipsis;
        width: 190px;
        overflow: hidden;
      }

      .questionWrapper {
        display: flex;
        padding: 10px 0 0;
        align-items: center;

        .questionNumber {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.75rem;
          border: 2px solid #0B2541;
          border-radius: 50%;
          min-width: 20px;
          min-height: 20px;
          margin-right: 10px;
        }

        .questionName {
          font-size: 0.9375rem;
        }

        .selectedItem-circle {
          color: #0A7B24;
          border: 2px solid #0A7B24;
        }

        .selectedItem {
          color: #0A7B24;
        }
      }
    }

    .arrowControlsTeachingPath {
      display: flex;
      background: #D3D9DE;
      padding: 20px;
      flex-direction: column;

      .navigationButton {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        cursor: pointer;

        span {
          margin-left: 10px;
          font-size: 0.9375rem;
        }
      }

      .navigationExitButton {
        display: flex;
        align-items: center;
        cursor: pointer;
        background: transparent;
        span {
          margin-left: 10px;
          font-size: 0.9375rem;
          color: #AD005F;
        }
      }
    }
  }

  .teachingPathWrapper {
    display: flex;
    height: calc(100% - #{$header-height});

    .wrapperTeachingPath{
      width: 100%;

      .contentTeachingPath {
        height: 100%;
        width: 100%;
      }
    }
  }
}

@media screen and (device-width: 810px) and (device-height: 1080px) and (orientation:landscape) {
  //.passageTeachingPath .teachingPathWrapper .wrapperTeachingPath .contentTeachingPath{
  //  height: calc(100% - 100px);
  //}
  .passageTeachingPath .teachingPathNavigate .arrowControlsTeachingPath{
    position: fixed;
    bottom: 0;
    width: 230px;
  }
}
