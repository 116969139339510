.modalContentTGAssig {
    position: fixed;
    left: 50%;
    top: calc(40% - 0.59em);
    transform: translate(-50%, -28%);
    width: 700px;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 0.3rem;
    outline: 0;
    z-index: 15;
    display: none;

    /* word-break: break-all; */
    &__header {
        padding: 1rem;
        border-bottom: 1px solid #e9ecef;
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
        padding: 25px 10px 20px 50px;

        h1 {
            text-transform: uppercase;
            color: #AD005F;
            font-size: 1rem;
            clear: both;
            margin-bottom: 3px;
        }

        span {
            text-decoration: none !important;
            text-transform: none !important;
            color: #000 !important;
            font-size: 1rem !important;
            clear: both;
        }

        &__close {
            position:absolute;
            right: -15px;
            top: -15px;
            cursor: pointer;            
            background: transparent;
            padding: 0px;
            svg {
                display: block;
                height: 30px;
                width: 30px;
            }
            &:focus {
                outline: black;
                outline-style: auto;
                outline-offset: 2px;
            }
            .roundedCircle {
                fill: white;
            }
            &:hover {
                .roundedCircle {
                    fill: rgb(211, 217, 222);
                }  
            }
        }
    }

    &__body {
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 25px 30px 0px 50px;
        max-height: 400px;
        overflow-y: auto;

        &__row {
            padding: 0px 0px;

            &.first {
                padding: 15px 0px;
            }

            h4 {
                display: flex;
                margin: 5px 0px;
                align-items: center;
                margin-bottom: 20px;
            }
        }

        .line {
            border-top: 1px solid #e9ecef;
            padding: 20px 0px;
        }

        .DescriptionEditor {
            width: 100% !important;
            font-size: 14px !important;
        }

        .nestedOrderNumber {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 31px;
            height: 31px;
            min-height: 31px;
            background: #AD005F;
            border-radius: 50%;
            color: #ffffff;
            font-size: 1.0625rem;
            margin: 0 12px 0 0;
            padding: 0px 10px;
            border: 2px solid #AD005F;
            float: left;
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        padding: 20px 10px 25px 46px;
        border-top: 1px solid #e9ecef;

        &__aligLeft {
            display: flex;

            button {
                width: auto;
                padding: 0 23px;
                margin-top: auto;
                min-width: inherit;
            }
        }

        &__aligRight {
            margin-top: 9px;
            margin-right: 25px;

            button {
                display: flex;
                background: transparent;
                &:focus {
                    outline: black;
                    outline-style: auto;
                    outline-offset: 2px;
                }
                img {
                    margin-left: 5px
                }
            }

            button.downloading {
                cursor: progress;

                img {
                    visibility: hidden;
                }
            }
        }
    }
}

.modalContentTGAssig.open {
    display: block;
    z-index: 19;
}

.horizontalLine {
    width: 135px;
    display: block;
    margin: 35px auto 30px auto;
    border-bottom: 1px solid #d3d9de;

    &.withOutButton {
        margin-top: 5px;
    }
}

.modalContentTGAssigBackground {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 17;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .4;
}

.modalContentTGAssigBackground.hide {
    display: none;
}

.expansion {
    display: flex;
    font-weight: 300;
    font-style: italic;
    overflow: hidden;
    align-items: baseline;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 0.5px solid #8e8e8e48;
    opacity: 0.6;
    .toggleRead {
        width: 20px;
        margin-right: 3px;
        margin-left: 2px;
        border-radius: 50%;
        &:focus {
            outline: black;
            outline-style: auto;
            outline-offset: 2px;
        }
    }
}

.full {
    max-height: 42px;
    padding-bottom: 10px;
}

.paragraph {
    text-overflow: ellipsis;
    min-width: 95%;
}

.toggleRead {
    display: flex;
    align-items: center;

    img {
        color: #8e8e8e;
        width: 20px;
        margin-right: 5px;
        transform: rotate(-90deg);
        transition: 0.5s
    }

    &.active img {
        color: #8e8e8e;
        transform: rotate(90deg);
    }

    p {
        font-size: 13px;
        font-weight: 700;
        margin: 0px;
        color: #0b2541;

    }
}

.image {
    color: #8e8e8e;
}

.divHidden { 
    display: none;
}