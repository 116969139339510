.contentListSearch {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    height: calc(100vh - 250px);
    overflow-y: scroll;
    align-content: flex-start;
    .Inside {
        margin-bottom: 20px;
        .cardInfoItem {
            .triangleCard {
                width: 100%;
                position: relative;
                &:before {
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 10px 15px 0;
                    border-color: #737474 transparent transparent;
                    position: absolute;
                    left: 50%;
                    margin-left: -12px;
                }
                &:after {
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 10px 15px 0;
                    border-color: white transparent transparent;
                    position: absolute;
                    left: 50%;
                    margin-left: -12px;
                    top: -1px;
                }
            }
            &__card {
                cursor: pointer;
                background: white;
                border-radius: 5px;
                overflow: hidden;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.100661);
                min-height: 250px;
                &.active {
                    position: relative;
                    &:before {
                        content: '';
                        position: absolute;
                        pointer-events: none;
                        width: calc(100% - 2px);
                        height: calc(100% - 2px);
                        background: transparent;
                        border: 1px solid #737474;
                        border-radius: 6px;
                        left: 0px;
                        top: 0px;
                    }
                }
            }
            &__detail {
                overflow: hidden;
                pointer-events: all;
                z-index: 3;
                height: 0px;
                transition: 0.8s;
                &.active {
                    height: 630px;
                    margin-top: 20px;
                }
                .cardTemplateArticle {
                    width: calc(100% - 20px);
                    background: white;
                    position: absolute;
                    left: 0;
                    border: 1px solid #737474;
                    overflow: hidden;
                    border-radius: 5px;
                    &__header {
                        padding: 20px;
                        &__top {
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-start;
                            margin-bottom: 20px;
                            .description {
                                max-width: 650px;
                                font-weight: 300;
                                font-size: 16px;
                                line-height: 22px;
                                color: #0a003d;
                            }
                            .close {
                                border: solid 1px black;
                                border-radius: 50%;
                                cursor: pointer;
                            }
                        }
                        &__bottom {
                            display: flex;
                            align-items: center;
                            .links {
                                margin-right: 40px;
                                ul {
                                    display: flex;
                                    a {
                                        display: flex;
                                        align-items: center;
                                        margin-right: 10px;
                                        background: #AD005F;
                                        color: white;
                                        font-size: 14px;
                                        text-transform: uppercase;
                                    }
                                }
                            }
                            .relateds {
                                h3 {
                                    margin-top: 0px;
                                    margin-bottom: 10px;
                                    font-weight: 300;
                                }
                                ul {
                                    display: flex;
                                    li {
                                        margin-right: 15px;
                                        a {
                                            font-size: 18px;
                                            font-weight: 700;
                                            text-transform: capitalize;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &__body {
                        background: #e6e9ed;
                        padding: 30px 20px;
                        box-sizing: border-box;
                        height: 400px;
                        overflow-y: scroll;
                        &__top {
                            display: flex;
                            margin-bottom: 10px;
                        }
                        &__item {
                            flex: 1;
                            margin-bottom: 20px;
                            h2 {
                                margin: 0px;
                                display: flex;
                                align-items: center;
                                font-size: 18px;
                                font-weight: 500;
                                margin-bottom: 15px;
                                img {
                                    width: 22px;
                                    margin-right: 10px;
                                }
                            }
                            .list {
                                ul {
                                    display: flex;
                                    flex-wrap: wrap;
                                    li {
                                        background: #d3d9de;
                                        font-size: 14px;
                                        margin-right: 5px;
                                        margin-bottom: 5px;
                                        padding: 3px 6px;
                                        border-radius: 5px;
                                        color: #0a003d;
                                        font-weight: 300;
                                        a {
                                            white-space: nowrap;
                                        }
                                    }
                                }
                            }
                        }
                        &__goals {
                            ul {
                                .goalData {
                                    display: flex;
                                    font-size: 16px;
                                    border-bottom: 1px solid rgba(0,0,0,.0901960784);
                                    padding: 10px 0;
                                    color: #0a003d;
                                    font-weight: 300;
                                    &__grade {
                                        margin-right: 20px;
                                        min-width: 110px;
                                        text-decoration: none;
                                    }
                                    &__subject {
                                        margin-right: 20px;
                                        min-width: 150px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &__imagen {
                img {
                    width: 100%;
                    height: auto;
                    height: 180px;
                    object-fit: cover;
                }
            }
            &__description {
                padding: 10px;
                overflow: hidden;
                height: auto;
                position: relative;
                /*&:before {
                    content: '';
                    position: absolute;
                    width: calc(100% - 2px);
                    height: 37px;
                    text-align: right;
                    background: rgb(255,255,255);
                    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 54%);
                    bottom: 1px;
                    left: 1px;
                    border-radius: 6px;
                }*/
                .title {
                    h2 {
                        margin: 0px;
                        font-size: 18px;
                        line-height: 22px;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        display: -webkit-box;
                        overflow: hidden;
                        font-weight: 400;
                    }
                }
                .description {
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 20px;
                    -webkit-line-clamp: 6;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    overflow: hidden;
                    max-height: 120px;              
                }
            }
            &__left {
                display: flex;
                align-items: center;
                img {
                    height: 60px;
                    margin-right: 10px;
                    width: 90px;
                    object-fit: cover;
                }
                h2 {
                    font-size: 17px;
                    font-weight: 400;
                }
            }
            &__right {
                display: flex;
                align-items: center;
                .subjectItems {
                    display: flex;
                    align-items: center;
                    .subjectItem {
                        font-size: 0.9375rem;
                        padding: 7px 18px 8px 21px;
                        background: #E7ECEF;
                        border: 1px solid #D3D9DE;
                        border-radius: 17px;
                        margin-right: 10px;
                    }
                }
            }
            &__tooltip {
                position: absolute;
                box-shadow: 0 2px 2px rgba(255, 255, 255, 0.129753);
                border-radius: 4px;
                border: 1px solid #939fa7;
                padding: 13px 20px 13px 20px;
                cursor: default;
                background: #FFF;
                z-index: 20;
                top: 42px;
                right: 2px;
                min-width: 185px;
                &:before {
                    content: '';
                    border-top: 1px solid #939fa7;
                    border-right: 1px solid #939fa7;
                    width: 12px;
                    height: 12px;
                    position: absolute;
                    background: white;
                    transform: rotate(-45deg);
                    top: -8px;
                    right: 11px;
                }
                li {
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
            }
            &.cardInfoItem--relative {
                position: relative;
            }
            &.cardInfoItem--TP {
                position: relative;
                .cardInfoItem--absolute {
                    top: auto !important;
                    bottom: 30px;
                }
                .cardInfoItem__description {
                    padding-right: 45px;
                }
            }
            .cardInfoItem--horizontal {
                min-height: 60px;
                height: 60px;
                display: flex;
                justify-content: space-between;
                position: relative;
                overflow: inherit;
                cursor: pointer;
                background: white;
                border-radius: 5px;
                overflow: hidden;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.100661); 
                padding-right: 45px;
            }
            .cardInfoItem--absolute {
                position: absolute;
                top: 15px;
                right: 5px;
                .more-show {
                    button {
                        background: transparent;
                    }
                }
            }
        }
        &.Inside--horizontal {
            width: 100%;
            margin-right: 0px;
            min-height: 60px;
            height: 60px;
        }
    }
}
.previewModalInfoSearch {
    position: fixed;
    top: 78px;
    width: 100%;
    height: calc(100% - 78px);
    z-index: 20;
    left: 0;
    &__background {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(54, 57, 71, 0.3);
        z-index: 1;
    }
    &__content {
        position: absolute;
        height: 100%;
        width: 780px;
        background: white;
        right: 0;
        z-index: 2;
        .headerButtons {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 10px 12px;
            .previewButtons {
                position: relative;
                margin-left: 15px;
            }
            .functionsButtons {
                position: relative;
                margin-left: 15px;
            }
            .DistributeButtons {
                margin-left: 15px;
            }
            .modalContent {
                border: 1px solid #D3D9DE;
                border-top: 0;
                border-radius: 5px;
                box-shadow: 0 2px 4px 0 #ffffff1c;
                position: absolute;
                z-index: 2;
                background: white;
                padding: 12px 20px;
                right: 0px;
                padding-top: 17px;
                min-width: 220px;
                button {
                    background: transparent;
                    display: block;
                    margin-bottom: 5px;
                    text-align: left;
                    white-space: nowrap;
                }
                .actionboldButton {
                    button {
                        font-size: 17px;
                        font-weight: 500;
                        display: block !important;
                        position: relative;
                        margin-top: 8px;
                        margin-bottom: 8px !important;
                        width: 100% !important;
                        padding: 0px;
                    }
                    &.actionAfter {
                        button {
                            &:after {
                                content: '';
                                position: absolute;
                                width: 8px;
                                height: 8px;
                                border-bottom: solid 2px #0b2541;
                                border-right: solid 2px #0b2541;
                                transform: rotate(-45deg);
                                top: 5px;
                                right: 3px;
                            }
                        }
                    }
                }
                .linkOpenSite {
                    display: block;
                    position: relative;
                    margin-top: 8px;
                    margin-bottom: 8px;
                    &:after {
                        content: '';
                        position: absolute;
                        width: 8px;
                        height: 8px;
                        border-bottom: solid 2px #0b2541;
                        border-right: solid 2px #0b2541;
                        transform: rotate(-45deg);
                        top: 5px;
                        right: 0px;
                    }
                    &.LinkRollback {
                       &:after { display: none; }
                    }
                }
                .modalContentInside {
                    position: absolute;
                    top: 0;
                    border: 1px solid #D3D9DE;
                    border-top: 0;
                    border-radius: 5px;
                    box-shadow: 0 2px 4px 0 #ffffff1c;
                    background: white;
                    padding: 12px 20px;
                    width: 100%;
                    left: 0;
                    min-height: 100%;
                    pointer-events: none;
                    opacity: 0;
                    transition: 0.3s;
                    z-index: 2;
                    &.active {
                        pointer-events: all;
                        opacity: 1;
                    }
                    h2 {
                        display: block;
                        margin: 0px;
                        font-size: 17px;
                        border-bottom: solid 1px #0000001c;
                        padding-bottom: 5px;
                        margin-bottom: 5px;
                        a {
                            display: block;
                            width: 100%;
                            position: relative;
                            padding-left: 20px;
                            font-size: 17px;
                            &:before {
                                content: '';
                                position: absolute;
                                width: 10px;
                                height: 10px;
                                border-bottom: solid 2px #0b2541;
                                border-left: solid 2px #0b2541;
                                transform: rotate(45deg);
                                top: 5px;
                                left: 0px;
                            }
                        }
                    }
                    ul {
                        li {
                            margin-bottom: 10px;
                            margin-top: 10px;
                            a {
                                display: block;
                                position: relative;
                            }
                            &.itemListFlex {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                p {
                                    margin: 0px;
                                }
                                div {
                                    display: flex;
                                    svg {
                                        display: block;
                                        border: solid 1px #979797;
                                        width: 26px;
                                        height: 26px;
                                        border-radius: 50%;
                                        padding: 5px;
                                        path {
                                            fill: #061626;
                                        }
                                    }
                                    a {
                                        margin-left: 7px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .modalToggle {
                border: solid 2px #d3d9de;
                border-radius: 20px;
                padding: 7px 16px;
                display: block;
                min-width: 100px;
                text-align: center;
                color: #0b2541;
                &.active {
                    border: solid 2px #0b2541;
                }
                &:after {
                    content: '';
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    border-left: solid 2px #0b2541;
                    border-bottom: solid 2px #0b2541;
                    transform: rotate(-45deg);
                    margin-left: 8px;
                    top: -3px;
                    position: relative;
                }
            }
        }
        .NewTitleHeader {
            background: #0b2541;
            padding-top: 26px;
            padding-bottom: 27px;
            h2 {
                margin: 0px;
                text-align: center;
                color: white;
                font-size: 36px;
                font-weight: 400;
            }
        }
        .entityInfo {
            height: 67px;
            border-bottom: 1px solid #D3D9DE;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            .partsInfo {
                margin-left: 10px;
                margin-right: 10px;
                display: flex;
                font-size: 13px;
                align-items: center;
                min-width: 90px;
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 350px;
                &.partsInfoAutor {
                    p {
                        white-space: nowrap;
                        max-width: 180px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
                &.partsInfoList {
                    ul {
                        display: flex;
                        li {
                            margin-right: 5px;
                            &:after {
                                content: ',';
                            }
                            &:last-child {
                                margin-right: 0;
                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
                img {
                    margin-right: 10px;
                    max-width: 22px;
                }
            }
        }
        .entityDescription {
            /* height: 67px; */
            border-bottom: 1px solid #D3D9DE;
            padding: 20px;
            display: flex;
            align-items: left;
            justify-content: left;
            height: auto !important;
            .partsInfoDescription {
                margin-left: 15px;
                margin-right: 15px;
                display: flex;
                font-size: 17px;
            }
        }
        .summary {
            display: block;
            padding: 20px;
            background: white;
            overflow: auto;
            height: calc(100vh - 385px) !important;
            .entityInfoBlock {
                display: -webkit-inline-box;
                max-width: 46%;
                min-width: 46%;
                vertical-align: top;
                padding: 10px 0px;
                margin: 10px;
                .imageGrep {
                    margin-right: 10px;
                    .imgInfo {
                        width: 21px;
                        height: 21px;
                    }
                }
                .title {
                    color: #0b2541;
                    font-size: 16px;
                    font-weight: 500;
                    height: 20px;
                    white-space: nowrap;
                    margin-bottom: 10px;
                    width: 100%;
                }
                .listItem .item {
                    margin: 0;
                    padding: 0;
                    text-decoration: none;
                    display: inline-block;
                    background-color: #d3d9de;
                    border-radius: 4px;
                    color: #0b2541;
                    font-size: 13px;
                    font-weight: 300;
                    padding: 4px;
                    margin: 5px 5px 5px 0px;
                }
            }
            .entityInfoBlockExpanded {
                display: -webkit-inline-box;
                max-width: 46%;
                min-width: 46%;
                vertical-align: top;
                padding: 10px 0px;
                margin: 10px;
                .imageGrep {
                    margin-right: 10px;
                    .imgInfo {
                        width: 21px;
                        height: 21px;
                    }
                }
                .title {
                    color: #0b2541;
                    font-size: 16px;
                    font-weight: 500;
                    height: 20px;
                    /* width: 56px; */
                    white-space: nowrap;
                    margin-bottom: 10px;
                    width: 100%;
                }
            }
            .flexContainerExpanded {
                .listItem {
                    margin: 0px 44px 0px 44px;
                    .goalData {
                        display: flex;
                        font-size: 16px;
                        border-bottom: 1px solid rgba(0,0,0,.0901960784);
                        padding: 10px 0;
                        color: #0a003d;
                        font-weight: 300;
                        &__grade {
                            margin-right: 20px;
                            min-width: 110px;
                            text-decoration: none;
                        }
                        &__subject {
                            margin-right: 20px;
                            min-width: 150px;
                        }
                    }
                }
            }
        }
    }
}
.contentListSearch {
    .Inside {
        width: calc(100% / 7 - 20px);
        margin-right: 20px;
    }
}

.body_ARTICLES {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.body_ARTICLES .css-1y8mk30-SkeletonTheme {
    width: calc(16.66% - 20px);
    flex: none;
    height: 250px;
}
.body_ARTICLES .css-1y8mk30-SkeletonTheme .SearchListItem__skeleton {
    height: 250px !important;
}
.SearchPage__body {
    .css-1y8mk30-SkeletonTheme {        
        margin-bottom: 20px;
    }
}
@media screen and (max-width: 2350px) {
    .contentListSearch {
        .Inside {
            width: calc(100% / 7 - 20px);
            margin-right: 20px;
        }
    }
}
@media screen and (max-width: 2150px) {
    .contentListSearch {
        .Inside {
            width: calc(100% / 6 - 20px);
            margin-right: 20px;
        }
    }
}
@media screen and (max-width: 2000px) {
    .contentListSearch {
        .Inside {
            width: calc(100% / 6 - 20px);
            margin-right: 20px;
        }
    }
}
@media screen and (max-width: 1800px) {
    .contentListSearch {
        .Inside {
            width: calc(100% / 5 - 20px);
            margin-right: 20px;
        }
    }
    .body_ARTICLES .css-1y8mk30-SkeletonTheme {
        width: calc(20% - 20px);
    }
}
@media screen and (max-width: 1500px) {
    .contentListSearch {
        .Inside {
            width: calc(100% / 4 - 20px);
            margin-right: 20px;
        }
    }
    .body_ARTICLES .css-1y8mk30-SkeletonTheme {
        width: calc(25% - 20px);
    }
}
@media screen and (max-width: 1250px) {
    .contentListSearch {
        .Inside {
            width: calc(100% / 3 - 20px);
            margin-right: 20px;
        }
    }
    
    .body_ARTICLES .css-1y8mk30-SkeletonTheme {
        width: calc(33.33% - 20px);
    }
}
@media screen and (max-width: 1000px) {
    .contentListSearch {
        .Inside {
            width: calc(100% / 3 - 20px);
            margin-right: 20px;
        }
    }
    .body_ARTICLES .css-1y8mk30-SkeletonTheme {
        width: calc(33.33% - 20px);
    }
    .previewModalInfoSearch {
        &__content {
            width: calc(100% - 50px);
            .headerButtons {
                flex-wrap: wrap;
                .modalToggle {
                    min-width: 90px;
                    padding: 7px 10px;
                }
            }
            .NewTitleHeader {
                padding-top: 16px;
                padding-bottom: 17px;
                h2 {
                    font-size: 25px;
                }
            }
            .entityInfo {
                flex-wrap: wrap;
                height: auto;
                padding: 10px 0px;
                padding-top: 5px;
                justify-content: flex-start;
            }
            .entityDescription {
                padding: 10px 0px;
            }
            .summary {
                padding: 10px;
                .entityInfoBlock {
                    max-width: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    margin: 0px;
                    overflow: hidden;
                    .title {
                        max-width: calc(100% - 44px);
                    }
                }
                .entityInfoBlockExpanded {
                    width: 100%;
                    max-width: 100%;
                    box-sizing: border-box;
                    margin: 0px;
                    .title {
                        max-width: calc(100% - 44px);
                    }
                }
                .listItem {
                    min-height: auto !important;
                    max-width: calc(100% - 44px);
                }
                .flexContainerExpanded {
                    .listItem {
                        margin-left: 44px;
                        margin-right: 0px;
                        .goalData {
                            display: block;
                            .goalData__grade {
                                font-weight: 700;
                                margin-bottom: 5px;
                                margin-right: 0px;
                            }
                            .goalData__subject {
                                margin-right: 0px;
                                margin-bottom: 5px;
                                font-weight: 700;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    } 
    .contentListSearch {
        .Inside .cardInfoItem__detail.active {
            height: 910px;
        }
        .Inside .cardInfoItem__detail .cardTemplateArticle__header__bottom {
            display: block;
            .links {
                margin-right: 0px;
                ul {
                    display: block;
                    a {
                        justify-content: center;
                    }
                }
            }
            .relateds {
                margin-top: 10px;
            }
        }
        .Inside .cardInfoItem__detail .cardTemplateArticle__body {
            padding: 15px 20px;
        }
        .Inside .cardInfoItem__detail .cardTemplateArticle__body__top {
            display: block;
        }
        .Inside .cardInfoItem__detail .cardTemplateArticle__body__goals ul .goalData {
            display: block;
            .goalData__grade {
                font-weight: 700;
                margin-bottom: 5px;
            }
            .goalData__subject {
                margin-right: 0px;
                font-weight: 700;
                font-size: 14px;
                margin-bottom: 5px;
            }
        }
    }
}
@media screen and (max-width: 870px) {
    .SearchPage__header__top__left {
        width: calc(100% - 400px);
    }
    .SearchPage__header__top__right a {
        margin-top: 0px !important;
    }
}
@media screen and (max-width: 750px) {
    .contentListSearch {
        .Inside {
            width: calc(100% / 2 - 20px);
            margin-right: 20px;
        }
    }
    .body_ARTICLES .css-1y8mk30-SkeletonTheme {
        width: calc(50% - 20px);
    }
}

@media screen and (max-width: 600px) {
    .contentListSearch {
        .Inside {
            width: calc(100% / 2 - 20px);
            margin-right: 20px;
        }
    }
    .SearchPage__header__top {
        display: block !important;
    }
    .SearchPage__header__top__left {
        width: 100% !important;
        margin-bottom: 10px;
    }
    .SearchPage__header__top__right {
        width: 100% !important;
        justify-content: space-between !important;
    }
    .SearchPage__header__top__right a {
        margin: 0px !important;
        width: calc(50% - 5px);
    }
    
    .body_ARTICLES .css-1y8mk30-SkeletonTheme {
        width: calc(50% - 20px);
    }
}


@media screen and (max-width: 500px) {
    .contentListSearch {
        .Inside {
            width: calc(100% / 2 - 20px);
            margin-right: 20px;
        }
    }
    
    .body_ARTICLES .css-1y8mk30-SkeletonTheme {
        width: calc(50% - 20px);
    }
}

@media screen and (max-width: 401px) {
    .SearchPage__header__top__right {
        display: block !important;
    }
    .SearchPage__header__top__right a {
        margin: 0px !important;
        width: 100% !important;
        margin-bottom: 10px !important;
    }
    .contentListSearch.articlesList {
        margin-right: 0px !important;
    }
    .contentListSearch {
        .Inside {
            width: 100%;
            margin-right: 0px;
        }
    }
    
    .body_ARTICLES .css-1y8mk30-SkeletonTheme {
        width: 100%;
    }
}