@import "../../../style/index";

.AppHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 11px solid $color-purple;
  padding: 13px 15px 12px 20px;
  border-bottom: 1px solid $color-gray;
  background: $color-white;
  position: relative;
  z-index: 16;
  &__left {
    display: flex;
    .AppHeader__block {
      margin-right: 15px;
    }
  }
  &__right {
    display: flex;
    align-items: center;
    .barSearch {
      position: relative;
      img {
        position: absolute;
        pointer-events: none;
        top: 11px;
        left: 13px;
      }
      input {
        border-radius: 28px;
        border: solid 1px #cccccc;
        padding: 10px 5px;
        padding-left: 37px;
        width: 180px;
     } 
    }
  }
  .copyButton {
    button {
      margin: 0px 5px;
    }
  }
  &__block {
    display: flex;
    align-items: center;
    min-width: 0;
  }
  &__logo {
    margin-bottom: 7px;
  }
  &__role {
    margin-left: 17px;
    font-family: 'Roboto Slab', serif;
    color: $color-text-secondary;
    font-size: 1.0625rem;
    font-weight: 400;
  }
  &__navigationItem {
    @include text-shrink;
    display: flex;
    align-items: center;
    margin-left: 19px;
    cursor: pointer;
    min-width: 0;
    a {
      transition: 0.3s;
      &:hover {
        color: #AD005F;   
      }
      &.permanActive {
        color: #AD005F;   
      }
    }
    &.helpNavigation {
      margin-left: 20px;
      margin-right: 10px;
      .AppHeader__dropdown::after {
        display: none !important;
      }
      .AppHeader__submenuWrapper {
        right: 0px;
      }
    }
  }
  &__navigationItemText {
    @include text-shrink;
    min-width: 0;
    transition: 0.3s;
    &:hover {
      color: #AD005F;   
    }
  }
  .closeHeaderTp {
    display: flex;
    align-items: center;
    cursor: pointer;
    p {
      margin: 0px;
    }
    img {
      margin-left: 10px;
    }
  }
}

.AppHeader__block_mobile {
  display: none;
}

.AppHeader__navigation {
  display: flex;
  align-items: center;
  min-width: 0;
}



.AppHeader__navigationItemImage {
  width: 25px;
  height: 25px;
}

.AppHeader__navigationItemText:hover .AppHeader__submenuWrapper {
  opacity: 1;
  pointer-events: all;
}

.AppHeader__submenuWrapper {
  opacity: 0;
  pointer-events: none;
  padding-top: 21px;
  position: absolute;
}

.AppHeader__submenu {
  min-width: 220px;
  background: #FFF;
  border: 1px solid #D3D9DE;
  border-top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(255, 255, 255, 0.11);
}

.AppHeader__dropdown {
  align-items: center;
  display: flex;
}

.AppHeader__dropdown::after {
  filter: brightness(0);
  display: inline-block;
  content: '';
  width: 16px;
  height: 16px;
  margin-left: 10px;
  background-image: url(../../../assets/images/arrow-down.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.AppHeader__dropdown:hover {
  text-decoration: underline;
}

.AppHeader__dropdownItem {
  text-decoration: none;
  font-weight: 300;
  transition: background-color .315s ease-in-out, color .315s ease-in-out;
  padding: 10px 15px;
  position: relative;
}

.AppHeader__dropdownItem:hover {
  background: #E7ECEF;
}

.AppHeader__dropdownItem__subMenu {
  position: absolute;
  width: auto;
  right: 0;
  top: 0;
  background: white;
  z-index: 2;
  transform: translateX(100%);
  border: 1px solid #D3D9DE;
  border-top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 2px 4px 0 ffffff1c;
  opacity: 0;
  pointer-events: none;
  &.subMenuItemActive {
    right: auto;
    left: 0;
    transform: translateX(-100%);
  }
}
li.AppHeader__dropdownItem__subItem {
  text-decoration: none;
  font-weight: 300;
  transition: background-color .315s ease-in-out, color .315s ease-in-out;
  padding: 10px 15px;
  position: relative;
}
li.AppHeader__dropdownItem__subItem:hover {
  background: #E7ECEF;
}
.AppHeader__dropdownItem:hover ul.AppHeader__dropdownItem__subMenu {
  opacity: 1;
  pointer-events: all;
}
.AppHeader__userLogo {
  @include circle(37px);
  margin-left: 10px;
  margin-top: 2px;
  cursor: pointer;
}

.AppHeader__headerOverlay {
  display: none;
}

.AppHeader__btnHeaderGuidance {
  display: none;
}

.AppHeader__button {
  width: 22px;
  height: 19px;
  padding: 0;
  background: transparent;
  //justify-content: flex-end;
}

.AppHeader__userMenuButton {
  padding-left: 12px;
}

.AppHeader__navigation_tablet {
  display: none;
}

.AppHeader__modal_animated-enter,
.AppHeader__modal_animated-enter-active,
.AppHeader__modal_animated-exit,
.AppHeader__modal_animated-exit-active
{
  transition: transform 200ms ease-in-out, opacity 200ms ease-in-out; // TODO rewrite animate-many and use here
}


.AppHeader__modal_animated-enter {
  transform: scale(0.8) translateY(-20px);
  opacity: 0;
}

.AppHeader__modal_animated-enter-active {
  transform: translateY(0);
  opacity: 1;
}

.AppHeader__modal_animated-exit {
  transform: translateY(0);
  opacity: 1;
}

.AppHeader__modal_animated-exit-active {
  transform: scale(0.85) translateY(-20px);
  opacity: 0;
}


@media screen and (max-width: $screen-dimension-desktop) {
  .AppHeader {
    padding-top: 18px;
    padding-bottom: 7px;
    padding-right: 29px;
  }

  .AppHeader__navigation {
    display: none;
  }

  .AppHeader__navigation_tablet {
    display: flex;
  }

  .AppHeader__userLogo {
    margin-top: 0;
  }
}

@media screen and (max-width: $screen-dimension-tablet) {
  .AppHeader {
    padding-top: 24px;
    padding-bottom: 22px;
    padding-right: 16px;
    padding-left: 15px;
  }

  .AppHeader__block {
    display: none;
  }

  .AppHeader__block_mobile {
    display: flex;
    justify-content: space-between;
  }
  .AppHeader__buttonMobile {
    background: transparent;
  }
  .AppHeader__navigation_tablet {
    display: none;
  }
  .copyButton {
    display: none !important;
  }
}

.modalKeyboard {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  &__background {
    background: #00000069;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  &__content {
    position: absolute;
    background: white;
    padding: 20px 30px;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border-radius: 10px;
    min-width: 330px;
    padding-top: 15px;
  }
  &__list {
    margin-top: 25px;
    &:first-child {
      margin-top: 0px;
    }
    h2 {
      font-size: 18px;
      border-bottom: solid 1px #0000002e;
      padding-bottom: 10px;
    }
    li {
      display: flex;
      font-size: 14px;
      justify-content: space-between;
      align-items: center;
      line-height: 15px;
      border-bottom: solid 1px #97979729;
      p {
        margin: 8px 0px;
      }
    }
  }
  &__close {
    position: absolute;
    width: 30px;
    height: 30px;
    top: -10px;
    right: -10px;
    border-radius: 50%;
    border: solid 2px;
    cursor: pointer;
    background: white;
    &:focus {
      outline: black;
      outline-style: auto;
      outline-offset: 4px;
    }
    &:before {
      content: '';
      position: absolute;
      width: 17px;
      height: 2px;
      background: #0b2541;
      transform: rotate(45deg);
      top: 12px;
      left: 5px;
    }
    &:after {
      content: '';
      position: absolute;
      width: 17px;
      height: 2px;
      background: #0b2541;
      transform: rotate(-45deg);
      top: 12px;
      left: 5px;
    }
  }
}

.singleListElements {
  li {
    margin: 5px 0px;
    a {
      color: #000;
      display: block;
      text-decoration: none;
      padding: 5px 0;
      font-size: 1.125rem;
      font-weight: 400;
      &.BtnFinal {
        display: flex;
        background: #AD005F;
        border-radius: 20px;
        color: white;
        padding: 10px 20px;
        font-weight: 300;
        &.btnDisabled {
          pointer-events: none;
          opacity: 0.4;
        }
        img {
          width: 19px;
          margin-right: 8px;
        }
        p {
          color: white;
          margin: 0px;
        }
      }
    }
  }
}
.singleElements {
  position: absolute;
  top: 80px;
  right: 20px;
  background: white;
  padding: 20px 15px;
  box-shadow: 0 3px 5px 0 #00000024;
  ul {
    li {
      margin: 0px;
      width: 100%;
      display: block;
      margin-bottom: 10px;
      .AppHeader__dropdown {
        font-size: 18px;
        font-weight: 300;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid #E9ECEF;
        padding: 5px 0px;
      }
      .AppHeader__submenuWrapper {
        position: relative;
        padding-top: 0px;
        display: none;
      }
      .AppHeader__dropdown:focus {
        display: flex;
      }
      .AppHeader__navigationItemText:hover .AppHeader__submenuWrapper {
        display: block;
      }
      .AppHeader__submenu {
        border: none;
        li {
          padding: 10px 0px;
          margin-bottom: 0px;
        }
      }
    }
  }
}

.singleListElementsSimple {
    margin: 5px 0px;
    margin-left: 20px;
    a {
      color: #000;
      display: block;
      text-decoration: none;
      padding: 5px 0;
      font-size: 1.125rem;
      font-weight: 400;
      &.BtnFinal {
        display: flex;
        background: #AD005F;
        border-radius: 20px;
        color: white;
        padding: 10px 20px;
        font-weight: 300;
        &.btnDisabled {
          pointer-events: none;
          opacity: 0.4;
        }
        img {
          width: 19px;
          margin-right: 8px;
        }
        p {
          color: white;
          margin: 0px;
        }
      }
    }  
}

.singleFlexElements {
  ul {
    display: flex;
    align-items: center;
    li {
      margin: 0px;
      margin-right: 20px;
      &:last-child {
        margin-right: 0px;
        margin-left: 20px;
      }
    }
  }
}

@media (max-width: 1158px) {
  .AppHeader__right .barSearch input {
    width: 120px;
  }
  .AppHeader__role {
    display: none;
  }
  .AppHeader__left .AppHeader__block {
    margin-right: 0px;
  }
}

@media (max-width: 987px) {
  .AppHeader__right .barSearch {
    display: none;
  }
}

@media (max-width: 870px) {
  .AppHeader__left {
    display: block;
    width: 0px;
  }
  .AppHeader__userMenuButton img {
    width: 2.5px;
  }
  .AppHeader__userMenuButton {
      border: solid 1.5px black;
      border-radius: 50%;
      width: 21px;
      height: 21px;
      padding-left: 0px;
      justify-content: center;
      margin-left: 5px;
  }
  .AppHeader__right {
    width: 100%;
    justify-content: space-between;
  }
  .AppHeader__navigationItem {
    display: none;
  }
}