.CustomImageFormSimple {
  &__filenameSpanCounter {
    margin-left: 10px !important;
    margin-right: 10px !important;
    font-weight: 300;
  }

  &__filenameSpanWarning {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  &__label {
    margin-bottom: 10px;
    display: block;
  }

  &__imagesList {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
  }

  &__imageListItem {
    /* position: absolute; */
    /* box-shadow: 0px 2px 4px #00000026;
    margin: 4px;
    padding: 18px;
    background-color: #d3d9de;
    border-radius: 5px;
    display: block; */
    box-shadow: 0px 2px 4px #00000026;

  }

  &__icon {
    background-color: rgb(18, 11, 77);
    width: 25px;
    height: 25px;
    border-radius: 20px;
  }

  &__filename {
    font-weight: 800;
    font-size: 1.0925rem;
    flex-wrap: nowrap;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }

  &__filesize {
    font-weight: 200;
    font-size: 1.0425rem;
  }

  &__spaced {
    margin-bottom: 10px;
  }

  &__completeInputsWarning {
    display: flex;
    margin: 10px !important;
  }

  &__previewImageUpload {
    /* max-width: 100%;
    max-height: 250px; */
    min-width: 100%;
    max-width: 100%;
    min-height: 220px;
    max-height: 220px;
    object-fit: cover;
    background-size: cover;
  }

  &__imageData {
    background: #AAAAAA;
    position: relative;
    z-index: 0;
  }

  &__imageUpload {
    /* position: relative;
    width: 320px; */
    position: relative;
    width: calc(50%);
    min-width: calc(50%);
    padding: 10px;
  }

  &__inputImage {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 16px;
    padding: 12px 16px;
    margin: 0px 0px 5px 0px;
    border: solid #596A75 1px;
    border-radius: 7px;
    background: white;
    box-shadow: 0 2px 4px 0 #d3d9de;
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: gray;
    opacity: 0.7; /* Firefox */
  }

  &__infoImage {
    background-color: #FFFFFF;
    padding: 12px;
  }

  &__disabled {
    background-color: #596A75;
    color: #FFFFFF;
    cursor: wait;
  }

  &__is-selectedImage-icon {
    cursor: pointer;
    position: relative;
    padding-top: 5px;
    width: 100%;
    /* height: 100%; */
    /* min-width: 90px;
    min-height: 90px; */
    display: flex;
    right: 4px;
    justify-content: flex-end;
    /* padding-left: 15px; */
    z-index: 1;
    /* justify-content: center;
    align-items: center; */
  }
}
