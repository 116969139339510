.DistributionActions {
  background-color: #ffffff;
  padding: 30px 20px 40px 20px;
  border-bottom: 1px solid #d3d9de;

  div {
    p {
      font-size: 1.0625rem;
      padding-left: 10px;
    }

    button {
      width: 55%;
      margin-top: 10px;
      padding: 15px 20px;
      border-radius: 25px;
      background-color: #D3D9DE;
      font-weight: 500;

      &:disabled {
        color: #939FA7;
        background-color: #D3D9DE;
        cursor: default;
      }
    }
  }

    .distributeLink {
      width: 100%;
      height: 54px;
      margin-top: 3px;
      padding: 10px 20px;
      background: #E7ECEF;
      border: 1px solid #596A75;
      border-radius: 5px;

      div {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      img {
        margin-left: 15px;
        width: 20px;
        cursor: pointer;
      }
    }
}
