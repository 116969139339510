@import '../../../style';

.NewAssignment {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 4;

  .main {
    margin-top: 80px;
    height: calc(100% - 80px);
    z-index: 22;
    width: 100%;
  }

  .blurShowed {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    position: fixed;
    z-index: 14;
    background-color: #0d101479;
  }

  .blur {
    display: none;
  }

  .notShow {
    scrollbar-color: transparent transparent;
  }

  .addAssignmentFlow {
    overflow: auto;
    height: 100%;
  }

  .backdrop {
    background: #0B2541;
    position: absolute;
    height: 100%;
    width: 50%;
    opacity: 0.35;
  }
}

.loading {
  display: flex;
  position: absolute;
  width: 100%;
  //width: calc(100vw - 211px);
  height: calc(100vh - 78px);
  z-index: 10;
  background: $color-light-gray;
  top: $header-height;
  left: 0;
  //left: $sidebar-width;
  justify-content: center;
  align-items: center;
}