.PublishingContent {
  position: relative;
  margin-left: 60%;
  width: 40%;
  height: 100%;
  background: #ffffff;

  .teachingPathContent {
    height: calc(100% - 311px);
  }
}
