.main {
  position: absolute;
  min-width: 100%;

  .teachingPathItemsContainer {
    position: relative;
    min-width: 100%;
    min-height: fit-content;
    // overflow-x: auto;
    &.draggableclass {
      .InfoCard {
        cursor: move;
        &:before {
          content: '';
          position: absolute;
          pointer-events: none;
          width: calc(100% - 6px);
          height: calc(100% - 6px);
          border: dashed 3px #4baf50;
          z-index: 1;
          border-radius: 5px;
        }
        button {
          pointer-events: none;
          &.activeDragButton {
            display: none;
          }
          &.desactiveDragButton {
            display: block;
            pointer-events: all;
          }
        }
      }
      .boxNodeOptionsChildren {
        pointer-events: none;
        .boxInformationDrop {
          display: none;
        }
      }
    }
    &.dragged {
      .topVerticalLine, .bottomVerticalLine, .bottomHorizontalLine, .teachingPathButtons, .childrenContainer {
        opacity: 0;
        pointer-events: none;
      }
    }
  
    &.rootContainer {
      padding-bottom: 50px;
      padding-top: 17px;
      padding-right: 40px;
      &::before {
        content: none;
      }

      .boxNodeOptionsRoot {
        background: #fff;
        margin-bottom: 27px;
        padding: 23px 0px 0px 0px;
        z-index: 3;
        position: relative !important;
        margin-left: 40px;
        &.imposibleDrop {
          border: dashed 3px #ff4545;
          background: #ffebeb;
          .teachingPathItemsTitle {
            background: transparent !important;
          }
          .numberAndActions button {
            background: transparent !important;
          }
        }
        &.posibleDrop {
          border: dashed 3px #4baf50;
        }
        .nestedOrderNumber {
          position: absolute;
          top: -15px !important;
        }
        &.bnoReadOnly {
          background:none;
        }
      }

      .boxNodeOptionsChildren {
        background: #fff;
        margin: 65px 0px 27px 0px;
        padding: 25px 0px 20px 0px;
        position: relative;
        z-index: 4;
        margin-left: 40px;
        &.imposibleDrop {
          border: dashed 3px #ff4545;
          background: #ffebeb;
          .teachingPathItemsTitle {
            background: transparent !important;
          }
          .numberAndActions button {
            background: transparent !important;
          }
        }
        &.posibleDrop {
          border: dashed 3px #4baf50;
        }

        .nestedOrderNumber {
          position: absolute;
          top: -15px !important;
        }

        &.bnoReadOnly {
          background:none;
        }

      }

      .nodeChildrenReadOnly {
        background:transparent;

        textarea {
          visibility: hidden;
        }

        .topVerticalLine {
          height: 90px;
          top: -50px;
        }
      }

      .sectImgs {
        margin-top: -8px;
        display: flex;
        justify-content: center;

        &.sectImgsReadOnly {
          margin-top: -48px;
          background: transparent;
          .topVerticalLine {
            height: 20px !important;
            min-height: 20px;
            top: -25px;
          }
        }
      }

      

    }

    &::before {
      content: "";
      width: 100%;
      height: 2px;
      background: #D3D9DE;
      position: absolute;
      top: -65px;
    }

    &.first::before {
      width: calc(50% - 40px);
      transform: translateX(calc(50% + 40px));
    }

    &.last::before {
      width: 50%;
      transform: translateX(-50%);
    }

    &.solo::before {
      width: 50%;
      // transform: translateX(0);
    }

    &.contentNone::before {
      content: none;
    }

    &.mergeLineBeforeButton::after {
      content: "";
      width: calc(50% - 20px);
      border-top: 2px dashed #D3D9DE;
      position: absolute;
      top: 414px;
      transform: translateX(calc(50% + 20px));
    }

    &.mergeLineFullWidth::after {
      content: "";
      width: 100%;
      border-top: 2px dashed #D3D9DE;
      position: absolute;
      top: 414px;
      transform: translateX(0);
    }

    &.mergeLineAfterButton::after {
      content: "";
      width: calc(50% + 20px);
      border-top: 2px dashed #D3D9DE;
      position: absolute;
      top: 414px;
      transform: translateX(calc(-50% + 20px));
    }
    .teachingPathItemsTitleDiv {
      position: relative;
      z-index: 2;
    }
    .teachingPathItemsTitle {
      margin: 0 0 17px 0;
      min-height: 34px;
      text-align: center;
      background-color: #ffffff;
      font-size: 1.4375rem;
      border: none;
      /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.0724978); */
      border-radius: 4px;
      resize: none;
      outline: none;
      z-index: 2;
      padding: 2px 0;

      &::placeholder {
        font-weight: 300;
      }
    }

    .unmergeImg {
      margin-top: 21px;
      width: 40px;
      height: 40px;
      z-index: 2;
      cursor: pointer;
      background: url(../../../../assets/images/unmerge.svg);
      background-size: cover;

      &:hover {
        background: url(../../../../assets/images/unmerged-hovered.svg);
        background-size: cover;
      }
      &:focus {
        outline: black;
        outline-style: auto;
      }
    }

    .nestedOrderNumberContainer {
      position: relative;
      min-height: fit-content;
      z-index: 4;

      &.withoutUnmergeButton {
        margin-top: 55px;
      }

      .topVerticalLine {
        height: 55px;
        min-height: 55px;
        left: 49px;
        top: -22px;
        z-index: 1;
      }
    }

    .infoCardsContainer {
      flex-direction: row;
      position: relative;
      min-height: fit-content;
      padding-left: 60px;
      padding-right: 20px;
      .infoCardContainer {
        position: relative;

        &::before {
          width: 100%;
          content: "";
          height: 2px;
          background: #D3D9DE;
          position: absolute;
          top: -65px;
        }

        &.first::before {
          width: 50%;
          transform: translateX(100%);
        }

        &.last::before {
          width: 50%;
          transform: translateX(0);
        }

        &.solo::before {
          width: 0%;
        }
      }
      &.first {
        .infoCardContainer {
          &.first {
            &:before {
              transform: translateX(100%);
            }
          }
        }
      }
    }

    .teachingPathItems {
      margin-top: 40px;
    }

    .teachingPathButtons {
      position: relative;
      margin-bottom: 20px;      
      margin-left: 40px;
      min-height: 308px;
      &.withUnmergeButton {
        margin-top: 10px;
      }

      &.withPadding {
        margin-top: 70px;
      }

      &.withoutPadding {
        margin-top: 15px !important;
      }      
    }

    .teachingPathButtonsLeft {
      margin-bottom: 20px;
      position: absolute;
      left: 0px;
      top: 88px;      
      cursor: pointer;
      width: calc(50% - 150px);
      height: 150px;
      .midLeftLine {
        width: 100%;
        min-width: 100%;
      }
      &.isFirst {
        width: 40px;
        left: auto;
        margin-left: -365px;
      }
      .circleOption {
        position: absolute;
        left: 0px;
      }
      &.withUnmergeButton {
        margin-top: 10px;
        top: 148px;
      }

      &.withPadding {
        margin-top: 70px;
      }

      &.withoutPadding {
        margin-top: 15px !important;
      } 
      .AddingButtons {
        position: absolute;
        transform: translateX(-105px)translateY(10px);
        z-index: 4;
      }     
    }

    .teachingPathButtonsRight {
      margin-bottom: 20px;
      position: absolute;
      right: -40px;
      top: 88px;  
      cursor: pointer;
      width: calc(50% - 150px);
      &.isLast {
        width: 40px;
        right: auto;
        margin-right: -445px;
      }
      .midRightLine {
        width: 100%;
      }
      .circleOption {
        position: absolute;
        right: 0;
      }
      &.withUnmergeButton {
        margin-top: 10px;    
        top: 148px;
      }
      .AddingButtons {
        position: absolute;
        transform: translateX(-105px)translateY(10px);
        z-index: 4;
      }
      &.withPadding {
        margin-top: 70px;
      }
      
      &.withoutPadding {
        margin-top: 15px !important;
      }      
    }

    .InfoCard {
      margin: 0 20px;
      position: relative;
      .actionButtons {
        left: 87px;
      }
    }

    .childrenContainer {
      position: relative;
      flex-direction: row;
      padding: 0px 25px;
      //z-index: 1;
    }

    .mergePanel {
      display: flex;
      margin-left: 40px;
      .mergeTooltip {
        display: none;
        background: #596A75;
        padding: 8px 20px;
        position: absolute;
        top: 345px;
        color: white;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        z-index: 5;

        &.enLocale {
          left: -73px;
        }

        &.nbLocale {
          left: -73px;
        }

        &.nnLocale {
          left: -73px;
        }
      }

      .unmergeTooltip {
        @extend .mergeTooltip;

        &.enLocale {
          left: inherit;
          margin-left: -48px;
        }

        &.nbLocale {
          left: inherit;
          margin-left: -41px;
        }

        &.nnLocale {
          left: inherit;
          margin-left: -41px;
        }
      }

      .mergeButton {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 395px;
        left: 0px;
        cursor: pointer;
        z-index: 3;
        background: url(../../../../assets/images/merge.svg);
        background-size: cover;
        &:focus {
          outline: black;
          outline-style: auto;
        }
      }

      &:hover {
        .mergeButton {
          background: url(../../../../assets/images/merge-hovered.svg);
          background-size: cover;
        }

        .mergeTooltip {
          display: flex;

          &:after {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            left: 50%;
            bottom: -12px;
            transform: translate(-50%, -50%) rotate(45deg);
            background-color: #596A75;
          }
        }

        .unmergeTooltip {
          display: flex;

          &:after {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            left: 50%;
            bottom: -12px;
            transform: translate(-50%, -50%) rotate(45deg);
            background-color: #596A75;
          }
        }
      }
    }


  }
  .dragButtonsItem {
    .desactiveDragButton {
      display: none;
    }
  }
  .topVerticalLine {
    border-left: 2px solid #D3D9DE;
    height: 65px;
    min-height: 65px;
    position: absolute;
    top: -65px;
    z-index: -1;
  }

  .midLeftLine {
    border-bottom: 2px solid #D3D9DE;
    /* height: 65px; */
    /* min-height: 65px; */
    width: 40px;
    min-width: 40px;
    position: absolute;
    top: 21px;
    left: 40px;
    z-index: -1;
  }

  .midRightLine {
    border-bottom: 2px solid #D3D9DE;
    /* height: 65px; */
    /* min-height: 65px; */
    width: 40px;
    min-width: 40px;
    position: absolute;
    top: 20px;
    right: 40px;
    z-index: -1;
  }

  .bottomVerticalLine {
    border-left: 2px solid #D3D9DE;
    height: 42px;
    position: absolute;
    bottom: -42px;
    z-index: 1;
  }

  .bottomHorizontalLine {
    border-bottom: 2px solid #D3D9DE;
    left: 223px;
    position: absolute;
    z-index: 1;
    bottom: -42px;
  }

  .exitButton {
    display: flex;
    width: 100%;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    a {
      &:focus {
        outline: black;
        outline-style: auto;
        outline-offset: -4px;
      }
    }
    .exitTeachingPath {
      padding: 20px;
      background: #E7ECEF;
      border-radius: 4px;

      span {
        margin-left: 10px;
        color: #AD005F;
      }
    }
  }
}
.main .withUnmergeButton .topVerticalLine {
  height: 40px;
  min-height: 40px;
  top: -40px;
}
.dropInfoAddtional {
  position: absolute;
  width: calc(100% - 74px);
  bottom: 0;
  max-width: 282px;
  z-index: 2;
  right: 40px;
  &.deletedrop {
    display: none;
  }
  &.insited {
    &:before {display: none}
  }
  &.posibleactive {
    .isPosibleDragInside {
      border: dashed 3px #4baf50;
    }
  }
  &.dropLeftArticle {
    &:before {
      content: '';
      position: absolute;
      width: calc(100% + 40px);
      height: 2px;
      background: #d3d9de;
      z-index: -1;
      top: 175px;
      left: calc(100% - 20px);
    }
  }
  &.dropLeftAssignments {
    &:before {
      content: '';
      position: absolute;
      width: calc(100% + 40px);
      height: 2px;
      background: #d3d9de;
      z-index: -1;
      top: 175px;
      left: calc(100% - 20px);
    }
  }
  &.dropRightArticle {
    &:before {
      content: '';
      position: absolute;
      width: calc(100% + 40px);
      height: 2px;
      background: #d3d9de;
      z-index: -1;
      top: 175px;
      right: calc(100% - 20px);
    }
  }
  &.dropRightAssignments {
    &:before {
      content: '';
      position: absolute;
      width: calc(100% + 40px);
      height: 2px;
      background: #d3d9de;
      z-index: -1;
      top: 175px;
      right: calc(100% - 20px);
    }
  }
  .isPosibleDragInside {
    width: 100%;
    text-align: center;
    border: dashed 2px black;
    height: 269px;
    padding: 20px;
    display: flex;
    align-items: center;
    background: white;
    border-radius: 5px;
    justify-content: center;
  }
}
.draggableclass .isPosibleDragInside {
  display: none;
}
.lastChildren {
  .dropInfoAddtional {
    margin-top: 20px;
    &:before { display: none; }
  }
}

.mainButtonsContent {
  border-bottom: 1px solid #d3d9de;
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 20px;
  .detailsModal {
    margin-right: 10px;
  }
  .btnTeacherguide {
    margin-top: 0px;
  }
  .horizontalLine {
    display: none;
  }
}

.boxInformationDrop {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: -3px;
  margin-top: -3px;
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  background-color: white;
  z-index: 4;
  border: dashed 2px black;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  pointer-events: none;
  border-radius: 5px;
  &.absBoxInformationDrop {
    width: 248px;
    left: 0px;
    transform: translateX(-102px);
    height: 250px;
    top: 54px;
  }
}

.centered {
  text-align: -webkit-center;
}