@import "style";

.StudentSettings {

  .levelIcon {
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
      width: 28px;
      height: 28px;
    }
  }

  .levelText {
    margin-top: 20px;
    font-size: 1.0625rem;
    font-weight: 300;

    span {
      font-weight: 500;
    }
  }

  .levelButtons {
    width: 320px;
    height: 54px;
    min-height: 54px;
    max-height: 54px;
    margin-top: 20px;

    button {
      display: flex;
      background-color: #FFFFFF;
      justify-content: center;
      align-items: center;
      flex: 1;
      border: 1px solid #0B2541;
      margin: 0;
      &:focus {
        outline: black;
        outline-style: auto;
        outline-offset: 2px;
      }
      &:first-child {
        border-radius: 5px 0 0 5px;
        border-right: none;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
        border-left: none;
      }

      &.active {
        cursor: default;
        background-color: #0B2541;
        border: 1px solid #0B2541;
        color: #ffffff;

        img {
          filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(300deg) brightness(107%) contrast(101%);
        }
      }

      img {
        margin-right: 10px;
      }
    }

    .activeNumber {
      color: white;
    }
  }
}

@media screen and (max-width: $screen-dimension-tablet) {
  .StudentSettings {
    .levelButtons {
      width: auto;
      max-width: 320px;
    }
  }

  .StudentSettings__header {
    display: none;
  }
}
