
.SelectCoverImage {

    width: 100%;
    /* overflow: auto; */
    height: 100%;
    &__header {
      height: 45%;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        display: block;
      }
    }
    &__body {      
      height: 70%;
      overflow: scroll;
      .CenterImagenes {
        &.centerMsj {
          text-align: center;
          p {
            font-size: 25px;
          }
        }
      }
    }

    .questions {
      margin-bottom: 40px;
      padding: 0px 20px;
  
      .question {
        margin-bottom: 10px;
        
  
        p {
          width: calc(100% - 25px);
          font-size: 1rem;
          font-weight: 300;
          margin: 0;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
        }
  
        &:last-child {
          font-weight: 500;
        }
  
        .questionNumber {
          font-weight: 500;
          margin-right: 10px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 2px solid #000;
          font-size: 0.625rem;
        }
      }
    }
  
    .title {
      margin: 0 0 10px 0;
      padding: 0px 20px;
      font-weight: 500;
      font-size: 1.0625rem;
    }
  
    .gradesSubjectsContainer {
      margin-bottom: 40px;
  
      .gradesSubjects {
        width: 100%;
        flex-wrap: wrap;
  
        .gradeSubject {
          display: inline-flex;
          padding: 7px 16px 7px 17px;
          font-size: 0.8125rem;
          margin: 0 10px 10px 0;
          background: #0B2541;
          border: 1px solid #071E37;
          border-radius: 25px;
          color: #ffffff;
        }
      }
    }
  }

.coverImageButton {
    text-align: left;
    width: 100%;
    height: 67px;
    margin: 10px 0px;
    padding-left: 10px;
    background: white;
    border-top: 1px solid #d3d9de;
    border-bottom: 1px solid #d3d9de;
    min-height: 67px;
    display: flex;
    align-items: center;
}

.coverImageButtonTab {
  cursor: pointer;
  text-align: center;
  border-bottom: 1px solid #d3d9de;
  width: 100%;
  height: 46px;
  background: white;
  font-size: 14px;
  font-weight: 600;
  min-height: 46px;
  text-transform: uppercase;
  padding-top: 6px;
  &:hover,
  &.selected,
  &.active {
    border-bottom: solid #AD005F 4px;
  }
  .buttonLabel {
    background: transparent;
    padding: 0px;
    &:focus {
      outline: black;
      outline-style: auto;
      outline-offset: 6px;
    }
  }
}

.buttonImgAddMedia {
  display: inline;
}

.gapOptionsImages{
    width: 100%;
    max-width: 100%;
    padding: 20px;
    display: flex;
    /* background-color: #e7ecef; */
    flex-wrap: wrap;
    overflow-y: scroll;
    button {
      padding: 0px;
      /* background: transparent; */
      position: relative;
      width: 33.33%;
      &:focus {
        outline: black;
        outline-style: auto;
        outline-offset: -2px;
      }
      .icon {
        display: none;
        position: absolute;
        width: 40px;
        height: 40px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &.active {
        .icon {
          display: block;
        }
        &:before {
          content: '';
          position: absolute;
          width: calc(100% - 11px);
          height: calc(100% - 11px);
          top: 3px;
          left: 3px;
          background: transparent;
          border: solid 3px black;
          pointer-events: none;
          background: #0000003d;
        }
      }
    }
}

.buttonContent {
    display: flex;
    padding-top: 12px
}

.imageForCover {
  width: 100%;
  /* display: inline-table; */
  /* max-width: 240px; */
  /* min-width: 240px; */
  max-height: 180px;
  height: 100%;
  /* flex-basis: fit-content; */
  /* object-fit: inherit; */
  object-fit: cover;
  padding: 5px;
}

.imageForCoverCustom {
  width: 100%;
  /* display: inline-table; */
  /* max-width: 240px; */
  /* min-width: 240px; */
  max-height: 180px;
  height: 100%;
  /* flex-basis: fit-content; */
  /* object-fit: inherit; */
  object-fit: cover;
  padding: 5px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}



.imageInfo {
  text-align: left;
  margin: 5px;
  margin-top: 0px;
  padding: 10px;
  background-color: white;
}

.rowImageInfo {
  flex-wrap: nowrap;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    margin: 5px 0px;
}

.fixed {
  min-height: 260px;
  max-height: 260px;
}

.contentWrapper {
  display: flex;
  background: #E7ECEF;
  flex-direction: column;
  height: calc(100% - 130px);
  padding: 20px;
  border-top: 1px solid #D3D9DE;

  .search-field-block {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 16px 18px;
    border: solid #596A75 1px;
    border-radius: 7px;
    background: white;
    box-shadow: 0 2px 4px 0 #d3d9de;
  }

  .search-input {
    width: 100%;
    border: none;
    font-weight: 300;
    font-size: 0.9375rem;
  }

  .attachments-list {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0 0 0;
    overflow: auto;      
    padding-bottom: 10px;
    &__img-wrap {
      margin: 17px 10px 0;
      width: calc(100% * (1 / 4) - 20px);
      background-color: rgba(0, 0, 0, 0.3);
      min-height: 200px;
      max-height: 300px;
      position: relative;

      button {
        padding: 0px;
        height: 100%;
        display: block;
        width: 100%;
        &:focus {
          outline: auto;
          outline-style: auto;
          outline-offset: 3px;
        }
      }

      &.disabled:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
      }

      &.selected {

        .videoDuration {
          margin: 10px 0 0 -5px;
          width: calc(100% + 10px);
        }
      }

      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
        object-fit: cover;
        background-size: cover;
      }

      .is-selected-icon {
        cursor: pointer;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 50px;
        pointer-events: none;
      }
    }

    .videoWrapper {
      display: flex;
      flex-direction: column;
      width: calc(100% / 2 - 10px);
      margin: 10px;

      &:nth-child(even) {
        margin-right: 0;
      }

      &:nth-child(odd) {
        margin-left: 0;
      }

      .attachments-list__video-wrap {
        width: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        position: relative;
        cursor: pointer;
        max-height: 100%;

        &.disabled:before {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3);
        }

        &.selected {
          border: #03203f solid 5px;

          .videoDuration {
            margin: 10px 0 0 -5px;
            width: calc(100% + 10px);
          }
        }

        img {
          width: 100%;
          height: 100%;
          cursor: pointer;
          object-fit: cover;
          background-size: cover;
        }

        .is-selected-icon {
          cursor: pointer;
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 2;
        }

        .playButton {
          height: 100%;
          width: 100%;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            height: 29px;
            width: 29px;
          }
        }
      }

      .videoDuration {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 5px;

        .duration {
          align-self: flex-start;
          margin-left: 10px;

          .clockDuration {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }

        .videoDuration {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 5px;

          .duration {
            align-self: flex-start;

            .clockDuration {
              width: 20px;
              height: 20px;
              margin-right: 10px;
            }
          }
        }
      }


    }
  }

  .skeleton-images-attachments-list {
    @extend .attachments-list;

    .css-1y8mk30-SkeletonTheme {
      width: calc(25% - 20px);
      margin: 17px 10px 0;

      .imageSkeletonLoader {
        @extend .attachments-list__img-wrap;
        margin: 0;
        width: 100%;
        background-color: #eee;
      }
    }
  }

  .skeleton-videos-attachments-list {
    @extend .attachments-list;

    .css-1y8mk30-SkeletonTheme {
      width: calc(50% - 10px);
      margin: 10px;

      &:nth-child(even) {
        margin-right: 0;
      }

      &:nth-child(odd) {
        margin-left: 0;
      }

      .videoSkeletonLoader {
        @extend .attachments-list__video-wrap;
        margin: 0;
        width: 100%;
        height: 100%;
        background-color: #eee;
      }
    }
  }
}