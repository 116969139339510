@import "../../../../style/index";

.AssignmentOverview {
  padding: 20px 24px 20px 20px;
  overflow-y: auto;
}

.AssignmentOverview__header {
  font-weight: 500;
  font-size: 1.0625rem;
}

.AssignmentOverview__questions {
  margin-top: 21px;
}

.AssignmentOverview__questionLink_disabled {
  cursor: default;
}

.Assignment__progressbar {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 10px;
  z-index: 2;
  pointer-events: none;
  overflow: hidden;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  &__content {
    height: 100%;
    position: absolute;
    left: 0px;
    background: #0a7b24;
    top: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.AssignmentOverview__question {
  margin-bottom: 10px;
  @include animate-many(color);
  font-size: 0.9rem;
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  padding-bottom: 1px;
  &.question_in {
    color: #AD005F;
    .AssignmentOverview__order {
      background: #AD005F;
      color: white;
      border-color: #AD005F;
    }
  }
  a {
    &:focus {
      color: #AD005F;
      .AssignmentOverview__order {
        border-color: #AD005F;
        background: #AD005F;
        color: white;
      }
    }
  }
}

.AssignmentOverview__question_disabled {
  cursor: default;
}

.AssignmentOverview__text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.AssignmentOverview__order {
  @include circle(20px);
  @include absolute-center-y(10px);
  @include flex-center;
  @include animate-many(border-color, color);
  left: 0;
  font-size: 0.75rem;
  margin-right: 10px;
  border: 2px solid $color-text-primary;
  padding-bottom: 1px;
}

.AssignmentOverview__question_answered {
  color: $color-green;
  border-color: $color-green;
  
}
