.detailsModal {
    button {
        display: flex;
        align-items: center;
        img {
            width: 23px;
            margin-right: 15px;
        }
    }
}
.modalContentTG__body__item {
    margin-bottom: 25px;
    h3 {
        display: flex;
        font-size: 18px;
        margin: 0px;
        margin-bottom: 5px;
        img {
            width: 18px;
            margin-right: 10px;
            display: block;
        }
    }
    ul {
        padding-left: 28px;
        &.modalContentTG__body__list {
            display: flex;
            flex-wrap: wrap;
            li {
                background-color: #d3d9de;
                border-radius: 4px;
                color: #0b2541;
                font-size: 13px;
                font-weight: 300;
                padding: 4px;
                margin: 5px 5px 5px 0px;
            }
        }
        &.modalContentTG__body__listGoals {
            li {
                margin-bottom: 10px;
                .subjectTitle {
                    margin: 10px 0px 4px 0px;
                    font-size: 17px;
                    font-weight: 900;
                    .description {
                        margin-top: 12px;
                        ul {
                            padding: 4px;
                            .goalsItem {
                                display: flex;
                                border-bottom: solid 1px #0000002b;
                                &__grade {
                                    width: 70px;
                                    flex: none;
                                    margin-right: 10px;
                                    font-weight: 700;
                                }
                                &__description {
                                    color: #09213a;
                                    padding-bottom: 10px;
                                }
                                
                            }
                        }
                    }
                    &.subjectTitleCont {
                        .description {
                            ul {
                                .goalsItem__grade { width: 105px; }
                            }
                        }
                    }
                }
                &:last-child {
                    .subjectTitle {
                        border-bottom: none;
                    }
                }
                .grade {
                    font-size: 14px;
                    font-weight: 700;
                }
                .description {
                    font-size: 15px;
                    font-weight: 300;
                    line-height: 22px;
                }
            }
            
        }
    }
}

.left {
    padding-left: 9px !important;
}