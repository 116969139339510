@import "style";

.AssignmentArticlesToReading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .loading {
    position: relative;
    background: transparent;
    height: 100%;
  }
  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    pointer-events: none;
    z-index: -1;
    top: 0px;
  }
}

.AssignmentArticlesToReading__title {
  font-size: 2.25rem;
  margin: 40px;
  text-align: center;
  font-weight: 500;
  padding: 5px;
  border-radius: 5px;
  &:focus {
    outline: #e9e9e994;
    outline-style: auto;
    outline-offset: 0px;
    background: #f7f7f7;
  }
}

.AssignmentArticlesToReading__articles {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.AssignmentArticlesToReading__card {
  width: 282px;
  margin: 0px 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.AssignmentArticlesToReading__defaultCursor {
  cursor: default;
}

.AssignmentArticlesToReading__checkbox {
  width: 32px;
  height: 32px;
  align-self: center;
  margin: 20px;
}

.AssignmentArticlesToReading {
  .cardBorder .InfoCard {
    border: 1px solid #596A75;
    height: auto;
  }
  .InfoCard {
    height: auto;
    margin-bottom: 10px;
  }

  .readArticle >:first-child {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.27)
  }

  .article {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 1;
    background: white;
    width: 100%;
    height: 100%;

    .headerArticle {
      box-sizing: border-box;
      width: 100%;
      background-color: #ffffff;
      height: 80px;
      border-top: 10px solid #AD005F;
      padding: 0 20px;
      border-bottom: 1px solid #e4e9ed;
      display: flex;
      justify-content: space-between;
      position: fixed;
      top: 0;

      .back {
        display: flex;
        align-items: center;
        cursor: pointer;

          span {
            margin: 0 7px;
            color: #0B2541;
            font-weight: 500;
            font-size: 0.9375rem;
          }
        }

      .finish {
        display: flex;
        align-items: center;
      }
    }

    .inlineArticle {
      display: flex;
      width: 100%;
      height: 100vh;
      padding-top: 80px;
      box-sizing: unset;
    }
  }
}

@media screen and (max-width: $screen-dimension-mobile) {
  .AssignmentArticlesToReading__title {
    font-size: 1.5rem;
    margin: 16px 0 21px 0;
    font-weight: 500;
    text-align: left;
  }
}


