@import "style";

.RelatedArticlesPreview {
  display: flex;
  height: calc(100% - 80px);
  position: fixed;
  width: 100%;
  z-index: 1;
  padding: 0;
  top: 80px;
  left: 0;
  background: #e7ecef;
  &__content {
    display: flex;
    width: 100%;
  }
  &__left {
    padding: 20px 25px;
    flex: none;
    width: 500px;
    height: 100%;
    overflow-y: scroll;
  }
  &__right {
    width: calc(100% - 500px);
    flex: none;
    border-left: 2px solid #d2d2d2;
  }
  .wrapperArticles {
    display: flex;
    flex-direction: column;
    width: 100%;

    .content {
      display: flex;
      width: 100%;
      height: calc(100% - 64px);
      padding: 20px 20px 0 20px;
      font-weight: bold;
      flex-direction: column;
      border-bottom: 0;

      .title {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 1rem;
        min-height: fit-content;

        span {
          color: #AD005F;
          text-transform: uppercase;
        }

        img {
          width: 29px;
          cursor: pointer;
          align-self: flex-start;
        }
      }

      &.loadingdata {
        .itemFilter__core {
          opacity: 0.5;
          pointer-events: none;
        }
        .itemFlexFilter {
          pointer-events: none;
        }
        .css-1wrxaxw-Control {
          opacity: 0.4;
          pointer-events: none;
        }
      }

      .SearchFilter {
        box-shadow: 0 0 7px -2px rgba(186, 186, 186, 1);

        .selector {
          border: 1px solid #D3D9DE;
          height: auto;
        }
      }

      .cards {
        display: flex;
        width: calc(100% + 9px);
        flex-wrap: wrap;
        margin: 20px 0 10px -10px;
        height: calc(100vh - 350px);
        overflow-y: scroll;

        .noResults {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          font-weight: 300;
          margin-top: 20%;
          font-size: 1.5rem;
          justify-self: center;
        }
      }
    }

    .selectedArticles {
      display: flex;
      height: 64px;
      background: #FFF;
      align-items: center;
      justify-content: space-between;
      padding-right: 20px;

      .counter {
        display: flex;
        background: #E7ECEF;
        margin: 10px 20px;
        border: 1px solid #939FA7;
        border-radius: 5px;
        align-items: center;
        padding: 7px 12px;
        height: 33px;
        font-size: 0.9375rem;
        font-weight: 300;
      }
    }
  }
}
.NewAssignment {
  .main {
    z-index: 21;
  }
  .articlesListHeader {
    width: 100%;
    background: #FFF;
    border-radius: 8px;
    box-shadow: 0 2px 2px 0 #d3d9de;
    border: 1px solid #D3D9DE;
    padding-left: 20px;
    padding-right: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    
    div {      
      font-size: 1rem;
      font-weight: 500;
      color: #AD005F;
      font-weight: 500;
      cursor: pointer;
      &__right {
        button {
          &:focus {
            outline: black;
            outline-style: auto;
            outline-offset: 0px;
          }
        }
      }
      button {
        background: transparent;
        position: relative;
        &:before {
          content: '';
          background: #AD005F;
          position: absolute;
          width: 35px;
          height: 35px;
          top: 7px;
          left: 5px;
          border-radius: 50%;
          opacity: 0;
        }
        &:hover {
          img {
            filter: grayscale(1)invert(1);
          }
          &:before {
            opacity: 1;
          }
        }
      }
    }
    &__left {
      display: flex;
      align-items: center;
      p {
        margin: 0px;
        margin-right: 25px;
        color: #AD005F;
      }
      a {
        color: #0b2541;
      }
    }
  
    img {
      cursor: pointer;
      width: 33px;
      height: 45px;
      position: relative;
      top: 1px;
    }
  }
}

.RelatedArticlesCard {
  display: flex;
  width: calc(100% / 7 - 12px);
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0 0 13px -4px rgba(87, 87, 87, 1);
  margin: 5px 10px;
  margin-bottom: 20px;
  max-height: 325px;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    box-shadow: 0 8px 10px #00000061;
  }

  .image {
    display: flex;
    width: 100%;
    height: 180px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    position: relative;
    padding: 0px;
    img {
      width: 100%;
      height: 100%;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      object-fit: cover;
    }

    .check {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      position: absolute;
      width: 36px;
      background: transparent;
      padding: 0px;
      right: 5px;
      top: 7px;
      &:focus {
        outline: solid;
        outline-style: auto;
        outline-offset: 2px;
      }
      .checkImg {
        width: 36px;
        height: 36px;
        z-index: 1;
        display: block;
      }
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    background: white;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    height: calc(100% - 180px);

    .titleText {
      @include text-shrink-multiline-not-break(2);
      font-size: 1.0625rem;
    }

    .cardDescription {
      @include text-shrink-multiline(3);      
      margin: 5px 0 0;
      font-size: 0.9375rem;
    }

    .levels {
      margin-top: 10px;
      padding: 10px 0 7px 0;
      border-top: 1px solid #D3D9DE;
      font-size: 0.8125rem;

      img {
        margin-right: 7px;
      }
    }

    .grades {
      display: flex;
      flex-wrap: wrap;
      margin: 5px 0 0 0;
      min-height: 33px;

      span {
        max-width: 33%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        background: #d4dadf;
        border-radius: 4px;
        font-size: 0.875rem;
        padding: 5px 7px;
        margin: 0 5px 5px 0;
        font-weight: 300;
      }
    }
  }
}

.NewAssignmentPreview .RelatedArticlesCard {
  margin: 10px 13px 10px 0;
  margin-right: 6px;
  margin-left: 6px;
}

.RelatedArticlesCard.selectedArticle {
  position: relative;
}
.RelatedArticlesCard.selectedArticle:before {
  content: '';
  position: absolute;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
  pointer-events: none;
  border: solid 1px;
  top: 0;
  left: 0;
  z-index: 2;
  border-radius: 6px;
}

@media screen and (max-width: 2350px) {
  .RelatedArticlesCard {    
    margin-right: 10px !important;
    margin-left: 10px !important;
    width: calc(100% / 6 - 20px);
  }
}

@media screen and (max-width: 2100px) {
  .RelatedArticlesCard {    
    margin-right: 15px !important;
    margin-left: 15px !important;
    width: calc(100% / 5 - 30px);
  }
}

@media screen and (max-width: 1950px) {
  .RelatedArticlesCard {    
    margin-right: 10px !important;
    margin-left: 10px !important;
    width: calc(100% / 5 - 20px);
  }
}

@media screen and (max-width: 1850px) {
  .RelatedArticlesCard {    
    margin-right: 6px !important;
    margin-left: 6px !important;
    width: calc(100% / 5 - 12px);
  }
}

@media screen and (max-width: 1800px) {
  .RelatedArticlesCard {
    margin-right: 15px !important;
    margin-left: 15px !important;
    width: calc(100% / 4 - 30px);
  }
}

@media screen and (max-width: 1650px) {
  .RelatedArticlesCard {
    margin-right: 10px !important;
    margin-left: 10px !important;
    width: calc(100% / 4 - 20px);
  }
}

@media screen and (max-width: 1600px) {
  .RelatedArticlesCard {
    margin-right: 6px !important;
    margin-left: 6px !important;
    width: calc(100% / 4 - 12px);
  }
}

@media screen and (max-width: 1500px) {
  .RelatedArticlesCard {
    margin-right: 20px !important;
    margin-left: 20px !important;
    width: calc(100% / 3 - 40px);
  }
}

@media screen and (max-width: 1367px) {
  .RelatedArticlesCard {
    margin-right: 10px !important;
    margin-left: 10px !important;
    width: calc(100% / 3 - 20px);
  }
}

@media screen and (max-width: 1200px) {
  .RelatedArticlesCard {
    margin-right: 10px !important;
    margin-left: 10px !important;
    width: calc(100% / 2 - 20px);
    max-height: 1000px !important;
    .image {
      height: auto;
      img {
        height: 250px;
        object-fit: cover;
      }
    }
  }
}

@media screen and (max-width: 1084px) {
  .RelatedArticlesCard {
    margin-right: 0px !important;
    margin-left: 0px !important;
    width: 100%;
    .image {
      img {
        height: auto;
        min-height: 150px;
        &.checkImg {
          min-height:36px;
        }
      }
    }
    .info {
      height: auto;
    }
  }
}

@media screen and (max-width: $screen-dimension-desktop) {
  .info {
    .grades {
      span {
        max-width: 100% !important;
        width: fit-content;
      }
    }
  }
}

