@import "../../../../../style";

.StudentOverviewHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.StudentOverviewHeader__text {
  @include text-shrink;
  font-size: 1.5rem;
}

.StudentOverviewHeader__image {
  object-fit: cover;
  width: 47px;
  height: 47px;
  border-radius: 25px;
}
