@import "style";

.previewModalInfo {
  display: flex;
  flex-direction: column;
  width: 780px;
  background: white;
  height: calc(100% - #{$header-height});
  right: 0;
  position: fixed;
  overflow-x: hidden;
  overflow-y: hidden;
  .partsInfo.partsInfoAutor p {
    white-space: nowrap;
    max-width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .DistributeButtons .modalContentInside {
    min-width: 190px;
    left: auto !important;
    right: 0;
    top: 45px !important;
  }
  .DistributeButtons .itemListFlex a {
    white-space: nowrap;
  }
  .partsInfoList {
    ul {
      display: flex;
      li {
        margin-right: 5px;
        &:after {
          content: ',';
        }
        &:last-child {
          margin-right: 0;
          &:after {
            content: '';
          }
        }
      }
    }
  }
  .headerButtons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 12px;
  }
  .previewButtons {
    position: relative;
    margin-left: 15px;
  }
  .functionsButtons {
    position: relative;
    margin-left: 15px;
  }
  .DistributeButtons {
    margin-left: 15px;
    .CreateButton {
      background: #AD005F;
    }
  }
  .actionDistribute {
    position: relative;
    .actionButton {
      button {
        background: #AD005F;
        color: white;
        position: relative;
        padding-right: 40px;
        &:before {
          content: '';
          position: absolute;
          width: 8px;
          height: 8px;
          border-left: solid 2px white;
          border-bottom: solid 2px white;
          transform: rotate(-45deg);
          right: 18px;
          top: 13px;
        }
      }      
    }
    .modalContentInside {
      position: absolute;
      top: 40px;
      border: 1px solid #D3D9DE;
      border-top: 0;
      border-radius: 5px;
      box-shadow: 0 2px 4px 0 #ffffff1c;
      background: white;
      padding: 12px 20px;
      width: 100%;
      left: 0;
      min-height: 100%;
      pointer-events: none;
      opacity: 0;
      transition: 0.3s;
      z-index: 2;
      h2 {
        display: block;
        margin: 0px;
        font-size: 17px;
        border-bottom: solid 1px #0000001c;
        padding-bottom: 5px;
        margin-bottom: 5px;
        a {
          display: block;
          width: 100%;
          position: relative;
          padding-left: 20px;
          font-size: 17px;
          &:before {

            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            border-bottom: solid 2px #0b2541;
            border-left: solid 2px #0b2541;
            transform: rotate(45deg);
            top: 5px;
            left: 0px;
          }
        }
      }
      li {
        margin-bottom: 10px;
        margin-top: 10px;
        &.itemListFlex {
          display: flex;
          justify-content: space-between;
          align-items: center;
          div {
            display: flex;
            a {
              margin-left: 7px;
            }
            svg {
              display: block;
              border: solid 1px #979797;
              width: 26px;
              height: 26px;
              border-radius: 50%;
              padding: 5px;
              path {
                fill: #061626;
              }
            }
          }
          p {
            margin: 0px;
          }
        }
        a {
          display: block;
          position: relative;
        }
      }
      &.active {
        pointer-events: all;
        opacity: 1;
      }
    }
  }
  .modalToggle {
    border: solid 2px #d3d9de;
    border-radius: 20px;
    padding: 7px 16px;
    display: block;
    min-width: 100px;
    text-align: center;
    color: #0b2541;
    &:after {
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      border-left: solid 2px #0b2541;
      border-bottom: solid 2px #0b2541;
      transform: rotate(-45deg);
      margin-left: 8px;
      top: -3px;
      position: relative;
    }
    &.active {
      border: solid 2px #0b2541;
    }
  }
  .actionboldButton {    
    button {
      font-size: 17px;
      font-weight: 500;
      display: block !important;
      position: relative;
      margin-top: 8px;
      margin-bottom: 8px !important;
      width: 100% !important;
      padding: 0px;
    }
    &.actionAfter {
      button {
        &:after {
          content: '';
          position: absolute;
          width: 8px;
          height: 8px;
          border-bottom: solid 2px #0b2541;
          border-right: solid 2px #0b2541;
          transform: rotate(-45deg);
          top: 5px;
          right: 3px;
        }
      }
    }
  }

  .modalContent {
    border: 1px solid #D3D9DE;
    border-top: 0;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 #ffffff1c;
    position: absolute;
    z-index: 2;
    background: white;
    padding: 12px 20px;
    right: 0px;
    padding-top: 17px;
    min-width: 220px;
    .linkOpenSite {
      display: block;
      position: relative;
      margin-top: 8px;
      margin-bottom: 8px;
      &:after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        border-bottom: solid 2px #0b2541;
        border-right: solid 2px #0b2541;
        transform: rotate(-45deg);
        top: 5px;
        right: 0px;
      }
      &.LinkRollback {
        &:after { display: none; }
      }
    }
    .modalContentInside {
      position: absolute;
      top: 0;
      border: 1px solid #D3D9DE;
      border-top: 0;
      border-radius: 5px;
      box-shadow: 0 2px 4px 0 #ffffff1c;
      background: white;
      padding: 12px 20px;
      width: 100%;
      left: 0;
      min-height: 100%;
      pointer-events: none;
      opacity: 0;
      transition: 0.3s;
      z-index: 2;
      h2 {
        display: block;
        margin: 0px;
        font-size: 17px;
        border-bottom: solid 1px #0000001c;
        padding-bottom: 5px;
        margin-bottom: 5px;
        a {
          display: block;
          width: 100%;
          position: relative;
          padding-left: 20px;
          font-size: 17px;
          &:before {

            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            border-bottom: solid 2px #0b2541;
            border-left: solid 2px #0b2541;
            transform: rotate(45deg);
            top: 5px;
            left: 0px;
          }
        }
      }
      li {
        margin-bottom: 10px;
        margin-top: 10px;
        &.itemListFlex {
          display: flex;
          justify-content: space-between;
          align-items: center;
          div {
            display: flex;
            a {
              margin-left: 7px;
            }
            svg {
              display: block;
              border: solid 1px #979797;
              width: 26px;
              height: 26px;
              border-radius: 50%;
              padding: 5px;
              path {
                fill: #061626;
              }
            }
          }
          p {
            margin: 0px;
          }
        }
        a {
          display: block;
          position: relative;
        }
      }
      &.active {
        pointer-events: all;
        opacity: 1;
      }
    }
    button {
      background: transparent;
      display: block;
      margin-bottom: 5px;
      text-align: left;
      white-space: nowrap;
      &:hover {
        background: #f1f1f1;
        border-radius: 5px;
        color:#AD005F;
      }
      &:focus {
          outline: auto;
      }
    }
  }
  .contentContainer {
    position: relative;
    flex: 1;
    overflow-y: auto;
    min-height: fit-content;

    .close-panel {
      display: none;
    }

    .headerPanel {
      width: 100%;
      height: calc(100vh / 4.5);
      overflow-x: hidden;

      .imagePlaceholder {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      .imageBlock {
        width: 100%;
        height: 100%;

        .shadow {
          background: #0B2541;
          opacity: 1.0;
          position: absolute;
          z-index: 1;
          width: 100%;
          height: calc(100vh / 4.5);
        }
      }

      .subjectInfo {
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
        line-height:
          /* calc(100vh / 6) */
          36px;
        height: calc(100vh / 4.5);
        padding: 10px;
        overflow: hidden;

        .subject {
          background: #071E37;
          border-radius: 20px;
          padding: 7px 17px;
          font-size: 0.8125rem;
          color: white;
          width: fit-content;
          margin-left: auto;
          margin-right: auto;
        }

        .entityTitle {
          /* margin: 40px 20px; */
          /* color: white;
          height: 100%; */
          /* font-size: 1.5rem; */
          /* font-size: 36px;
          font-weight: 500px;
          text-align: center;
          vertical-align: middle; */@import "style";

.previewModalInfo {
  display: flex;
  flex-direction: column;
  width: 800px;
  background: white;
  height: calc(100% - #{$header-height});
  right: 0;
  position: fixed;
  overflow-x: hidden;
  overflow-y: hidden;
  .NewTitleHeader {
    background: #0b2541;
    padding-top: 26px;
    padding-bottom: 27px;
    h2 {
      margin: 0px;
      text-align: center;
      color: white;
      font-size: 36px;
      font-weight: 400;
    }
  }
  .contentContainer {
    position: relative;
    flex: 1;
    overflow-y: auto;
    min-height: fit-content;

    .close-panel {
      display: none;
    }

    .headerPanel {
      width: 100%;
      height: calc(100vh / 4.5);
      overflow-x: hidden;

      .imagePlaceholder {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      .imageBlock {
        width: 100%;
        height: 100%;

        .shadow {
          background: #0B2541;
          opacity: 1.0;
          position: absolute;
          z-index: 1;
          width: 100%;
          height: calc(100vh / 4.5);
        }
      }

      .subjectInfo {
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
        line-height:
          /* calc(100vh / 4.5) */
          42px;
        height: calc(100vh / 4.5);
        padding: 10px;
        overflow: hidden;
        text-overflow: ellipsis;

        .subject {
          background: #071E37;
          border-radius: 20px;
          padding: 7px 17px;
          font-size: 0.8125rem;
          color: white;
          width: fit-content;
          margin-left: auto;
          margin-right: auto;
        }

        .entityTitle {
          /* margin: 40px 20px; */
          /* color: white;
          height: 100%; */
          /* font-size: 1.5rem; */
          /* font-size: 36px;
          font-weight: 500px;
          text-align: center;
          vertical-align: middle; */
          /* white-space: nowrap; */
          /* text-overflow: ellipsis; */
          /* overflow: hidden; */
          /* padding: 22px 85px; */
          color: white;
          display: table-cell;
          font-size: 36px;
          font-weight: 500px;
          width: 717px !important;
          height: calc(100vh / 4.5);
          padding: 0px 85px;
          margin: 10px 0px;
          /* border: 3px dashed #1c87c9; */
          vertical-align: middle;
          text-align: center;
          /* width: 100%; */
          min-width: 100%;
        }

        .infoTeacher {
          display: flex;
          flex-direction: column;
          align-items: center;

          .userPhoto {
            width: 48px;
            height: 48px;
            border-radius: 100%;
            margin-bottom: 10px;
          }

          .name {
            color: white;
            font-size: 1.0625rem;
            text-align: center;
          }

          .lesson {
            color: white;
            font-size: 0.9375rem;
            font-weight: 300;
            font-style: italic;
          }
        }
      }
    }

    .entityInfo {
      height: 67px;
      border-bottom: 1px solid #D3D9DE;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      .description {
        margin-top: 7px;
        margin-bottom: 7px;
        font-size: 17px;

      }

      .deadline {
        display: flex;
        font-size: 0.9375rem;
        align-items: center;
        margin-left: 9px;
        margin-right: 9px;

        img {
          margin-right: 10px;
        }
      }

      .passed {
        color: #AD005F;
      }

      .partsInfo {
        margin-left: 10px;
        margin-right: 10px;
        display: flex;
        font-size: 13px;
        align-items: center;
        min-width: 90px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 350px;
        &.partsInfoList {
          ul {
            display: flex;
            li {
              margin-right: 5px;
              &:after {
                content: ',';
              }
              &:last-child {
                margin-right: 0;
                &:after {
                  content: '';
                }
              }
            }
          }
        }
        img {
          margin-right: 10px;
          max-width: 22px;
        }
      }
    }

    .entityDescription {
      /* height: 67px; */
      border-bottom: 1px solid #D3D9DE;
      padding: 20px;
      display: flex;
      align-items: left;
      justify-content: left;
      height: auto !important;

      .description {
        margin-top: 7px;
        margin-bottom: 7px;
        font-size: 17px;
      }

      .partsInfoDescription {
        margin-left: 15px;
        margin-right: 15px;
        display: flex;
        font-size: 17px;

        img {
          margin-right: 10px;
        }
      }

      .deadline {
        display: flex;
        font-size: 0.9375rem;
        align-items: center;
        margin-left: 9px;
        margin-right: 9px;

        img {
          margin-right: 10px;
        }
      }

      .passed {
        color: #AD005F;
      }

      .partsInfo {
        margin-left: 10px;
        margin-right: 10px;
        display: flex;
        font-size: 13px;
        align-items: center;
        min-width: 90px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 350px;

        img {
          margin-right: 10px;
          max-width: 22px;
        }
      }
    }

    .summary {
      display: block;
      padding: 20px;
      background: white;
      overflow: auto;
      height: calc(100% - 290px);

      .entityInfoBlock {
        display: -webkit-inline-box;
        max-width: 46%;
        min-width: 46%;
        vertical-align: top;
        padding: 10px 0px;
        margin: 10px;

        .title {
          color: rgb(11, 37, 65);
          font-size: 16px;
          font-weight: 500;
          height: 20px;
          white-space: nowrap;
          margin-bottom: 10px;
          width: 100%;
        }


        .imageGrep {
          margin-right: 10px;

          .imgInfo {
            width: 21px;
            height: 21px;
          }
        }

        .listItem {

          .item {
            margin: 0;
            padding: 0;
            text-decoration: none;
            display: inline-block;
            background-color: #d3d9de;
            border-radius: 4px;
            color: #0b2541;
            font-size: 13px;
            font-weight: 300;
            padding: 4px;
            margin: 5px 5px 5px 0px;
          }


        }
      }

      .flexContainer {
        display: flex;

        .listItem {
          margin: 0px 44px 0px 44px;

          .item {
            margin: 0;
            padding: 0;
            text-decoration: none;
            display: inline-block;
            background-color: #d3d9de;
            border-radius: 4px;
            color: #0b2541;
            font-size: 13px;
            font-weight: 300;
            padding: 4px;
            margin: 5px 5px 5px 0px;
          }

          .itemExpanded {
            border-bottom: 1px solid #D3D9DE;
            margin-bottom: 15px;
            padding-bottom: 15px;

            .goalGrade {
              width: 97px;
              display: table-cell;
              height: auto;
              white-space: nowrap;
              color: #0b2541;
              font-size: 15px;
              font-weight: 300;
            }

            .goalDescription {
              display: table-cell;
              color: #0b2541;
              font-size: 15px;
              font-weight: 300;
              line-height: 22px;
              /* padding-left: 36px; */
            }
          }


        }
      }

      .flexContainerExpanded {
        display: flex;

        .listItem {
          margin: 0px 15px 0px 15px;

          .item {
            margin: 0;
            padding: 0;
            text-decoration: none;
            display: inline-block;
            background-color: #d3d9de;
            border-radius: 4px;
            color: #0b2541;
            font-size: 13px;
            font-weight: 300;
            padding: 4px;
            margin: 5px 5px 5px 0px;
          }

          .itemExpanded {
            border-bottom: 1px solid #D3D9DE;
            margin-bottom: 15px;
            padding-bottom: 15px;

            .goalGrade {
              width: 116px;
              display: table-cell;
              height: auto;
              white-space: nowrap;
              color: #0b2541;
              font-size: 15px;
              font-weight: 300;
            }

            .goalDescription {
              display: table-cell;
              color: #0b2541;
              font-size: 15px;
              font-weight: 300;
              line-height: 22px;
              /* padding-left: 36px; */
            }
          }


        }
      }

      .entityInfoBlockExpanded {
        display: -webkit-inline-box;
        max-width: 46%;
        min-width: 46%;
        vertical-align: top;
        padding: 10px 0px;
        margin: 10px;

        .title {
          color: rgb(11, 37, 65);
          font-size: 16px;
          font-weight: 500;
          height: 20px;
          /* width: 56px; */
          white-space: nowrap;
          margin-bottom: 10px;
          // testing...
          width: 100%;
        }


        .imageGrep {
          margin-right: 10px;

          .imgInfo {
            width: 21px;
            height: 21px;
          }
        }


      }

      .commentToEntity {
        font-weight: 300;
        overflow: auto;
        max-height: 90px;
        font-size: 1rem;
      }

      .view {
        margin-top: 20px;
        height: auto;
      }
    }
  }

  .answerButton {
    flex: 0;
    padding: 20px;
    border-top: 1px solid #D3D9DE;
    width: 100%;
    background-color: $color-white;
  }

  .footerButtons {
    display: flex;
    flex: 0;
    padding: 20px;
    border-top: 1px solid #D3D9DE;
    width: 100%;
    background-color: $color-white;
    &.flexButtons {
      justify-content: space-between;
      .left {
        display: flex;
      }
    }
    .actionButton {
      margin-right: 15px;
    }
  }
}

@media screen and (max-width: $screen-dimension-desktop) {
  .previewModalInfo .contentContainer .summary .commentToEntity {
    max-height: none;
  }

  .listItem {
    min-height: 68px;
    padding-right: 0px !important;
  }

  .summary {
    display: block;
    padding: 20px;
    background: white;
    overflow: auto;
    height: calc(100% - 212px) !important;
  }
}

@media screen and (max-width: 1536px) {
  .summary {
    height: calc(100% - 259px) !important;
  }
}

@media screen and (max-width: 1280px) {
  .summary {
    height: calc(100% - 212px) !important;
  }
}

@media screen and (max-width: $screen-dimension-tablet) {
  .previewModalInfo {
    left: 0;
    right: 0;
    top: $header-height-tablet;
    bottom: 0;
    width: 100%;
    overflow-y: auto;
    height: auto;
    display: block;

    .contentContainer {
      .close-panel {
        display: block;
        height: 35px;

        .close-button {
          position: absolute;
          right: 16.41px;
          top: 11.41px;
          width: 12.19px;
          height: 12.19px;
        }
      }

      .headerPanel {
        height: auto;
        position: relative;

        .imageBlock {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          .shadow {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: auto;
          }
        }

        .imagePlaceholder {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .subjectInfo {
          position: relative;
          height: auto;
          z-index: 1;
        }
      }

      .entityInfo {
        height: auto;
      }

      .summary {
        height: auto;
      }
    }

    .listItem {
      min-height: 68px;
      padding-right: 0px !important;
    }

    .answerButton {
      position: static;
    }

    .footerButtons {
      display: inline-block;
      flex: 0;
      padding: 20px;
      border-top: 1px solid #D3D9DE;
      width: 100%;
      background-color: $color-white;
      &.flexButtons {
        justify-content: space-between;
        .left {
          display: flex;
        }
      }
      .actionButton {
        margin-right: 8px;
      }
    }
  }
}
          /* white-space: nowrap; */
          /* text-overflow: ellipsis; */
          /* overflow: hidden; */
          /* padding: 22px 85px; */
          color: white;
          display: table-cell;
          font-size: 36px;
          font-weight: 500px;
          width: 717px !important;
          height: calc(100vh / 4.5);
          padding: 0px 85px;
          margin: 10px 0px;
          /* border: 3px dashed #1c87c9; */
          vertical-align: middle;
          text-align: center;
          /* width: 100%; */
          min-width: 100%;
        }

        .infoTeacher {
          display: flex;
          flex-direction: column;
          align-items: center;

          .userPhoto {
            width: 48px;
            height: 48px;
            border-radius: 100%;
            margin-bottom: 10px;
          }

          .name {
            color: white;
            font-size: 1.0625rem;
            text-align: center;
          }

          .lesson {
            color: white;
            font-size: 0.9375rem;
            font-weight: 300;
            font-style: italic;
          }
        }
      }
    }

    .entityInfo {
      height: 67px;
      border-bottom: 1px solid #D3D9DE;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      .description {
        margin-top: 7px;
        margin-bottom: 7px;
        font-size: 17px;

      }

      .deadline {
        display: flex;
        font-size: 0.9375rem;
        align-items: center;
        margin-left: 9px;
        margin-right: 9px;

        img {
          margin-right: 10px;
        }
      }

      .passed {
        color: #AD005F;
      }

      .partsInfo {
        margin-left: 10px;
        margin-right: 10px;
        display: flex;
        font-size: 13px;
        align-items: center;
        min-width: 90px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 350px;
        img {
          margin-right: 10px;
          max-width: 22px;
        }
      }
    }

    .entityDescription {
      /* height: 67px; */
      border-bottom: 1px solid #D3D9DE;
      padding: 20px;
      display: flex;
      align-items: left;
      justify-content: left;
      height: auto !important;

      .description {
        margin-top: 7px;
        margin-bottom: 7px;
        font-size: 17px;
      }

      .partsInfoDescription {
        margin-left: 15px;
        margin-right: 15px;
        display: flex;
        font-size: 17px;

        img {
          margin-right: 10px;
        }
      }

      .deadline {
        display: flex;
        font-size: 0.9375rem;
        align-items: center;
        margin-left: 9px;
        margin-right: 9px;

        img {
          margin-right: 10px;
        }
      }

      .passed {
        color: #AD005F;
      }

      .partsInfo {
        margin-left: 10px;
        margin-right: 10px;
        display: flex;
        font-size: 13px;
        align-items: center;
        min-width: 90px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 350px;
        img {
          margin-right: 10px;
          max-width: 22px;
        }
      }
    }

    .summary {
      display: block;
      padding: 20px;
      background: white;
      overflow: auto;
      height: calc(100% - 290px);

      .entityInfoBlock {
        display: -webkit-inline-box;
        max-width: 46%;
        min-width: 46%;
        vertical-align: top;
        padding: 10px 0px;
        margin: 10px;

        .title {
          color: rgb(11, 37, 65);
          font-size: 16px;
          font-weight: 500;
          height: 20px;
          white-space: nowrap;
          margin-bottom: 10px;
          width: 100%;
        }


        .imageGrep {
          margin-right: 10px;

          .imgInfo {
            width: 21px;
            height: 21px;
          }
        }

        .listItem {

          .item {
            margin: 0;
            padding: 0;
            text-decoration: none;
            display: inline-block;
            background-color: #d3d9de;
            border-radius: 4px;
            color: #0b2541;
            font-size: 13px;
            font-weight: 300;
            padding: 4px;
            margin: 5px 5px 5px 0px;
          }


        }
      }

      .flexContainer {
        display: flex;

        .listItem {
          margin: 0px 44px 0px 44px;

          .item {
            margin: 0;
            padding: 0;
            text-decoration: none;
            display: inline-block;
            background-color: #d3d9de;
            border-radius: 4px;
            color: #0b2541;
            font-size: 13px;
            font-weight: 300;
            padding: 4px;
            margin: 5px 5px 5px 0px;
          }

          .itemExpanded {
            border-bottom: 1px solid #D3D9DE;
            margin-bottom: 15px;
            padding-bottom: 15px;

            .goalGrade {
              width: 97px;
              display: table-cell;
              height: auto;
              white-space: nowrap;
              color: #0b2541;
              font-size: 15px;
              font-weight: 300;
            }

            .goalDescription {
              display: table-cell;
              color: #0b2541;
              font-size: 15px;
              font-weight: 300;
              line-height: 22px;
              /* padding-left: 36px; */
            }
          }
        }
      }

      .flexContainerExpanded {
        display: flex;

        .listItem {
          margin: 0px 15px 0px 15px;

          .item {
            margin: 0;
            padding: 0;
            text-decoration: none;
            display: inline-block;
            background-color: #d3d9de;
            border-radius: 4px;
            color: #0b2541;
            font-size: 13px;
            font-weight: 300;
            padding: 4px;
            margin: 5px 5px 5px 0px;
          }

          .itemExpanded {
            border-bottom: 1px solid #D3D9DE;
            margin-bottom: 15px;
            padding-bottom: 15px;

            .goalGrade {
              width: 116px;
              display: table-cell;
              height: auto;
              white-space: nowrap;
              color: #0b2541;
              font-size: 15px;
              font-weight: 300;
            }

            .goalDescription {
              display: table-cell;
              color: #0b2541;
              font-size: 15px;
              font-weight: 300;
              line-height: 22px;
              /* padding-left: 36px; */
            }
          }
        }
      }

      .entityInfoBlockExpanded {
        display: -webkit-inline-box;
        max-width: 46%;
        min-width: 46%;
        vertical-align: top;
        padding: 10px 0px;
        margin: 10px;

        .title {
          color: rgb(11, 37, 65);
          font-size: 16px;
          font-weight: 500;
          height: 20px;
          /* width: 56px; */
          white-space: nowrap;
          margin-bottom: 10px;
          // testing...
          width: 100%;
        }


        .imageGrep {
          margin-right: 10px;

          .imgInfo {
            width: 21px;
            height: 21px;
          }
        }


      }

      .commentToEntity {
        font-weight: 300;
        overflow: auto;
        max-height: 90px;
        font-size: 1rem;
      }

      .view {
        margin-top: 20px;
        height: auto;
      }
    }
  }

  .answerButton {
    flex: 0;
    padding: 20px;
    border-top: 1px solid #D3D9DE;
    width: 100%;
    background-color: $color-white;
  }

  .footerButtons {
    display: flex;
    flex: 0;
    padding: 20px;
    border-top: 1px solid #D3D9DE;
    width: 100%;
    background-color: $color-white;
    &.flexButtons {
      justify-content: space-between;
      .left {
        display: flex;
      }
    }
    .actionButton {
      margin-right: 10px;
      .CreateButton {
        padding: 0px 20px;
        font-size: 1rem;
      }
    }
  }
}

@media screen and (max-width: $screen-dimension-desktop) {
  .previewModalInfo .contentContainer .summary .commentToEntity {
    max-height: none;
  }

  .listItem {
    min-height: 68px;
    padding-right: 0px !important;
  }

  .summary {
    display: block;
    padding: 20px;
    background: white;
    overflow: auto;
    height: calc(100% - 212px) !important;
  }
}

@media screen and (max-width: 1536px) {
  .summary {
    height: calc(100% - 259px) !important;
  }
}

@media screen and (max-width: 1280px) {
  .summary {
    height: calc(100% - 212px) !important;
  }
}

@media screen and (max-width: $screen-dimension-tablet) {
  .previewModalInfo {
    left: 0;
    right: 0;
    top: $header-height-tablet;
    bottom: 0;
    width: 100%;
    overflow-y: auto;
    height: auto;
    display: block;

    .contentContainer {
      .close-panel {
        display: block;
        height: 35px;

        .close-button {
          position: absolute;
          right: 16.41px;
          top: 11.41px;
          width: 12.19px;
          height: 12.19px;
        }
      }

      .headerPanel {
        height: auto;
        position: relative;

        .imageBlock {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          .shadow {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: auto;
          }
        }

        .imagePlaceholder {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .subjectInfo {
          position: relative;
          height: auto;
          z-index: 1;
        }
      }

      .entityInfo {
        height: auto;
      }

      .summary {
        height: auto;
      }
    }

    .listItem {
      min-height: 68px;
      padding-right: 0px !important;
    }

    .answerButton {
      position: static;
    }

    .footerButtons {
      display: inline-block;
      flex: 0;
      padding: 20px;
      border-top: 1px solid #D3D9DE;
      width: 100%;
      background-color: $color-white;
      &.flexButtons {
        justify-content: space-between;
        .left {
          display: flex;
        }
      }
      .actionButton {
        margin-right: 8px;
      }
    }
  }
}