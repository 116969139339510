.content {
  padding: 40px 20px;
  border-bottom: 1px solid #d3d9de;
  background: #E7ECEF;

  .settingsWrapper {
    display: flex;
    flex-direction: column;

    .settingsImage {
      display: flex;
      align-items: center;

      img {
        height: 28px;
        width: 28px;
      }

      span {
        padding: 0 10px;
        color: #0B2541;
      }
    }

    .specify {
      font-size: 0.9375rem;
      font-weight: 300;
      margin: 10px 0 20px;
    }
  }
  .checkRelated {
    display: flex;
    align-items: center;
    .hiddenRelatedCheck {
      display: flex;
      align-items: center;
      font-size: 16px;
      margin-left: 40px;
      padding-right: 5px;
      img {
        margin: 5px;
        width: 20px;
        height: 20px;
      }
      &:focus {
        outline: black;
        outline-style: auto;
        outline-offset: 0px;
      }
    }
  }
  .articlesWrapper {

    .relatedArticleContent {
      padding-top: 16px;
    }

    button{
      font-size: 1.0625rem;
    }
    .tag {
      button {
        background: transparent;
        padding: 0px;
        &:focus {
          outline: black;
          outline-style: auto;
          outline-offset: 2px;
        }
      }
    }
  }
}
